import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../custom/components/templates/Layout'
import CustomTable from '../../custom/components/molecules/CustomTable'
import TransactionContext from '../../custom/context/transaction/transactionContext'
import CustomSearch from '../../custom/components/molecules/CustomSearch'
import { useFormik } from 'formik'

const Transaction = (props) => {
    const transactionContext = useContext(TransactionContext)

    const {
        all_refund,
        getAllRefund,
        responseStatus: responseStatusInvoice,
        clearResponse: clearResponseInvoice,
    } = transactionContext

    let action = props.match.params.action

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            fielddate: 'pr.created_at',
            fromdate: '',
            todate: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            getAllRefund(values)
        },
    })

    useEffect(() => {
        if (all_refund) {
            setTableBody(all_refund.records.length ? all_refund.records : [])
        }
    }, [all_refund])

    useEffect(() => {
        setTableBody([])
        formik.values.action = action
        formik.values.page = 1
        formik.values.fromdate = ''
        formik.values.todate = ''
        formik.values.searchterm = ''
        getAllRefund(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    const tableValues = [
        {
            field: 'return_invoice',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Invoice ID',
        },
        {
            field: 'trans_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Transaction ID',
        },
        {
            field: 'amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Amount',
        },
        {
            field: 'payment_method,mk_card_number,card_type',
            type: 'payment_method',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Card',
        },
        ,
        {
            field: 'first_name,last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
        {
            field: 'created_at',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Created Date',
        },
        {
            field: 'status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
    ]

    const tableActions = []

    const searchInfo = [
        {
            label: 'Invoice Number',
            placeholder: 'Enter invoice number',
            class: 'col-4',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
        {
            label: 'Created From Date',
            placeholder: 'Date Ending from',
            class: 'col-4',
            type: 'date',
            name: 'fromdate',
            formik: formik,
        },
        {
            label: 'Created To Date',
            placeholder: 'Date Ending To',
            class: 'col-4',
            type: 'date',
            name: 'todate',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="dashboard invoice">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_refund}
                    changeTableFunction={getAllRefund}
                />
            </div>
        </Layout>
    )
}

export default Transaction
