import React, { useContext, useEffect } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Dashboard from '../views/Dashboard'
import AuctionLots from '../views/AuctionLots'
import Report from '../views/Report'
import Login from '../views/Login'
import Listing from '../views/Listing'
import UserView from '../views/UserManagement/UserView'
import Employee from '../views/EmployeeManagement'
import TemplateView from '../views/Communications/TemplateView'
import StaticView from '../views/Settings/StaticView'
import ConfigView from '../views/Settings/ConfigView'
import BidIncrementView from '../views/Settings/BidIncrementView'
import BannerSettingsView from '../views/Settings/Banner/BannerSettings'
import EmailLogs from '../views/Communications/EmailLogs'
import Invoice from '../views/Invoice'
import Return from '../views/Return'
import TransactionPayment from '../views/Transaction/payment'
import TransactionRefund from '../views/Transaction/refund'
import TransactionReceipts from '../views/Transaction/Receipts'

import csc from 'country-state-city'

import AuthContext from '../custom/context/auth/authContext'
import CommonContext from '../custom/context/common/commonContext'

const Routes = () => {
    const authContext = useContext(AuthContext)
    const { loadUser } = authContext
    const commonContext = useContext(CommonContext)
    const { getGlobalVariable, setUSState } = commonContext

    useEffect(() => {
        if (localStorage.token) {
            loadUser()
        }
        getGlobalVariable()
    }, [])

    useEffect(() => {
        const USStates = []
        csc.getStatesOfCountry('231').map((lot) => {
            USStates.push({ value: lot.name, show: lot.name })
        })
        setUSState(USStates)
    }, [])

    return (
        <>
            <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/auction/:action" component={AuctionLots} />
                <PrivateRoute exact path="/report" component={Report} />
                <PrivateRoute exact path="/listing/:action" component={Listing} />
                <PrivateRoute exact path="/users/:action" component={UserView} />
                <PrivateRoute exact path="/employees/:action" component={Employee} />
                <PrivateRoute exact path="/communications/:action" component={TemplateView} />
                <PrivateRoute exact path="/communications/emailLogs" component={EmailLogs} />
                <PrivateRoute exact path="/invoice/:action" component={Invoice} />
                <PrivateRoute exact path="/return/:action" component={Return} />
                <PrivateRoute exact path="/transaction/payment" component={TransactionPayment} />
                <PrivateRoute exact path="/transaction/refund" component={TransactionRefund} />
                <PrivateRoute exact path="/transaction/receipts" component={TransactionReceipts} />
                <PrivateRoute exact path="/settings/static" component={StaticView} />
                <PrivateRoute exact path="/settings/general" component={ConfigView} />
                <PrivateRoute exact path="/settings/bid_increments" component={BidIncrementView} />
                <PrivateRoute exact path="/settings/banners" component={BannerSettingsView} />
            </Switch>
        </>
    )
}
export default withRouter(Routes)
