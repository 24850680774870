import React, { useEffect } from 'react'
import PrimaryButton from '../../../../custom/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../custom/components/atoms/SecondaryButton'
import { mapData } from '../../../../custom/common/components'
import './CustomSearch.css'

const CustomSearch = (props) => {
    const searchInfo = props.searchInfo
    const formik = props.formik

    let condition = false
    if (searchInfo.length) {
        condition = true
    } else if (searchInfo.data) {
        if (searchInfo.data.length) {
            condition = true
        }
    }
    return (
        <>
            {condition ? (
                <div className="commActBox d-flex justify-content-between align-items-center">
                    <div className="w-100">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="row flex-wrap d-flex justify-content-start align-items-center"
                        >
                            {mapData(searchInfo)}
                            <div className="col-8"></div>
                            {props.clearSearch ? (
                                <div className="col-2">
                                    <SecondaryButton label="CLEAR" onClick={props.clearSearch} />
                                </div>
                            ) : (
                                <div className="col-2"></div>
                            )}
                            <div className="col-2">
                                <PrimaryButton label="SEARCH" type="submit" />
                            </div>
                        </form>
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default CustomSearch
