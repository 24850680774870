import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../../custom/components/templates/Layout'
import CustomTable from '../../../custom/components/molecules/CustomTable'
import CustomSearch from '../../../custom/components/molecules/CustomSearch'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import SettingContext from '../../../custom/context/setting/settingContext'
import AlertContext from '../../../custom/context/alert/alertContext'
import BannerManage from './BannerManage'

const BidIncrementView = () => {
    const settingContext = useContext(SettingContext)
    const { responseStatus: responseStatusCommuncation, getAllBanners, banners } = settingContext
    const { setAlert } = useContext(AlertContext)
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            console.log('test')
            let index = banners.records.findIndex((ele) => {
                return ele.id == id
            })
            setManage({ popup, status, id, banner: banners.records[index] })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formik = useFormik({
        initialValues: {
            searchterm: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            getAllBanners(values)
        },
    })

    useEffect(() => {
        if (banners) {
            setTableBody(banners.records.length ? banners.records : [])
        }
    }, [banners])

    useEffect(() => {
        setTableBody([])
        formik.values.page = 1
        formik.values.searchterm = ''
        getAllBanners(formik.values)
        return () => {
            setTableBody([])
        }
    }, [])

    useEffect(() => {
        if (responseStatusCommuncation) {
            if (
                responseStatusCommuncation.from === 'updateBanners' &&
                responseStatusCommuncation.status === 'success'
            ) {
                toggleFullScreenPopup(false)
                setTableBody([])
                formik.values.page = 1
                formik.values.searchterm = ''
                getAllBanners(formik.values)
            }
        }
    }, [responseStatusCommuncation])

    const onClickEdit = (id) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    const deleteOnClick = (id) => {
        if (typeof id !== 'undefined' && id != 0) {
            let formData = { id: id }
            deleteBidIncrement(formData)
        }
    }

    const tableValues = [
        {
            field: 'bannerSrc',
            type: 'image',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Picture',
        },
        {
            field: 'tag',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Tag',
        },
        {
            field: 'url',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'URL',
        },
        {
            field: 'enabledMsg',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Enabled',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            deleteLink: true,
            deleteOnClick: deleteOnClick,
        },
    ]

    const searchInfo = [
        {
            label: 'Page',
            placeholder: 'Enter Page',
            class: 'col-4',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="dashboard staticPages">
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">note_add</span>Add New Banner
                    </Button>
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={[]}
                    allData={banners}
                    changeTableFunction={getAllBanners}
                />
            </div>
            <BannerManage data={manage} function={toggleFullScreenPopup} />
        </Layout>
    )
}
export default BidIncrementView
