import {
    GET_ALL_STATIC_PAGES,
    GET_SINGLE_STATIC_PAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_BID_INCREMENTS,
    GET_SINGLE_BID_INCREMENT,
    GET_ALL_BANNERS,
} from './settingTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_STATIC_PAGES:
            return {
                ...state,
                static_pages: action.payload,
            }
        case GET_SINGLE_STATIC_PAGE:
            return {
                ...state,
                single_static_page: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_ALL_BID_INCREMENTS:
            return {
                ...state,
                bid_increments: action.payload,
            }
        case GET_SINGLE_BID_INCREMENT:
            return {
                ...state,
                single_bid_increment: action.payload,
            }
        case GET_ALL_BANNERS:
            return {
                ...state,
                banners: action.payload,
            }
        default:
            return state
    }
}
