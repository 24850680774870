import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../../custom/components/atoms/PrimaryButton'
import SecondaryButton from '../../../custom/components/atoms/SecondaryButton'
import FullScreenPopup from '../../../custom/components/organisms/FullScreenPopup'
import AuctionContext from '../../../custom/context/auction/auctionContext'
import SettingContext from '../../../custom/context/setting/settingContext'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../../custom/common/components'
import Uploader from '../../../custom/common/uploader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FormatLineSpacing } from '@material-ui/icons'

const BannerManage = (props) => {
    const auctionContext = useContext(AuctionContext)
    const { uploadBanner } = useContext(SettingContext)
    const [reload, setReload] = useState(false)
    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        tag: Yup.string().required('Required!'),
        url: Yup.string()
            .max(400)
            .test('isValid', 'Invalid URL', (value) => {
                return new RegExp(
                    '^(https?:\\/\\/)?' + // protocol
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                        '(\\#[-a-z\\d_]*)?$',
                    'i',
                ).test(value)
            })
            .required('Required!'),
        fileName: Yup.array().required('Required!'),
        enabled: Yup.boolean(),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            tag: '',
            url: '',
            fileName: [],
            enabled: true,
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            console.log(values)
            if (values.enabled) values.enabled = 1
            else values.enabled = 0
            uploadBanner(values)
        },
    })

    useEffect(() => {
        if (data.id != 0) {
            formik.values.id = data.id
            formik.setFieldValue('tag', data.banner.tag)
            formik.setFieldValue('url', data.banner.url)
            formik.setFieldValue('fileName', [data.banner.file_name])
            if (data.banner.enabled) formik.setFieldValue('enabled', true)
            else formik.setFieldValue('enabled', false)
        } else {
            formik.handleReset()
        }
    }, [data])

    const resetFrom = () => {
        formik.values.tag = ''
        formik.values.url = ''
        formik.values.avatar = []
        formik.handleReset()
    }

    const inputFields = [
        {
            label: 'Banner Tag',
            type: 'text',
            placeholder: 'Enter your Banner Tag',
            class: 'col-sm-6 col-12',
            name: 'tag',
            formik: formik,
        },
        {
            label: 'Banner URL',
            type: 'text',
            placeholder: 'Enter URL',
            class: 'col-sm-6 col-12',
            name: 'url',
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'fileName',
            titleText: 'Upload banner image',
            innerText: 'Recommended dimensions: 1242px * 350px',
            class: 'col-12',
            folder: 'banner',
            multiple: false,
            accept: 'image/*',
        },
        {
            label: 'Enabled',
            placeholder: 'Enabled',
            type: 'checkbox',
            class: 'col-sm-6 col-12',
            name: 'enabled',
            formik: formik,
        },
    ]

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new' ? 'ADD NEW BANNER' : `EDIT BANNER: ${data.banner.tag} `
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <div className="row">{Object.values(mapData(inputFields))}</div>
                        <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default BannerManage
