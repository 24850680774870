import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import SecondaryButton from '../../custom/components/atoms/SecondaryButton'
import FullScreenPopup from '../../custom/components/organisms/FullScreenPopup'
import AuctionContext from '../../custom/context/auction/auctionContext'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../custom/common/components'
import Uploader from '../../custom/common/uploader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'

const UserManage = (props) => {
    const auctionContext = useContext(AuctionContext)
    const {
        single_auction,
        auction_projects,
        getSingleAuction,
        getAllAuctProjects,
        getAuctionTypes,
        auctionAction,
        auctionlot_types,
    } = auctionContext

    const [reload, setReload] = useState(false)

    const data = props.data
    const toggleFullScreenPopup = props.function
    const [activeAuctProjects, setActiveAuctProjects] = useState([])
    const [auctionlotTypes, setAuctionlotTypes] = useState([])

    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        description: Yup.string().required('Required!'),
        date_added: Yup.string().required('Required!'),
        date_closed: Yup.string().required('Required!'),
        ending_enable: Yup.boolean(),
        ending_items: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        ending_mins: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        // project_id: Yup.array().required('Required!'),
        avatar: Yup.array().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            title: '',
            description: '',
            date_added: dateTimeFormatFunction(new Date()),
            date_closed: dateTimeFormatFunction(new Date()),
            ending_enable: true,
            ending_items: 0,
            project_status: 'draft',
            ending_mins: 0,
            project_id: [],
            avatar: [],
            auctionlotTypeId: null,
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            values.date_added = moment(values.date_added).format()
            values.date_closed = moment(values.date_closed).format()
            console.log('values: ', values)
            auctionAction(values)
        },
    })

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleAuction(formik.values)
            getAllAuctProjects(formik.values)
            getAuctionTypes()
        } else {
            formik.values.id = 0
            getAllAuctProjects(formik.values)
            getAuctionTypes()
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        if (single_auction.record && data.status === 'edit') {
            const auction = single_auction.record
            const project_id = auction.auction_lots
                ? auction.auction_lots.split(',').map(Number)
                : []
            formik.values.title = auction.title
            formik.values.description = auction.description
            formik.values.project_id = project_id
            formik.values.date_added = dateTimeFormatFunction(auction.date_added)
            formik.values.date_closed = dateTimeFormatFunction(auction.date_closed)
            formik.values.ending_enable = auction.ending_enable === 1 ? true : false
            formik.values.ending_items = auction.ending_items
            formik.values.ending_mins = auction.ending_mins
            formik.values.avatar = auction.avatar.split(',')
            formik.values.auctionlotTypeId = auction.auctionlotTypeId
            formik.handleReset()
        } else {
            console.log('heree 111')
            resetFrom()
        }
    }, [single_auction])

    useEffect(() => {
        if (auctionlot_types) {
            let lotTypeOptions = auctionlot_types.map((ele) => {
                return { value: ele.id, show: ele.name }
            })
            setAuctionlotTypes(lotTypeOptions)
        }
    }, [auctionlot_types])

    const resetFrom = () => {
        formik.values.title = ''
        formik.values.description = ''
        formik.values.date_added = dateTimeFormatFunction(new Date())
        formik.values.date_closed = dateTimeFormatFunction(new Date())
        formik.values.ending_enable = true
        formik.values.ending_items = 0
        formik.values.ending_mins = 0
        formik.values.avatar = []
        formik.values.project_id = []
        formik.values.auctionlotTypeId = null
        formik.handleReset()
    }

    useEffect(() => {
        const projectToShow = []
        if (auction_projects && auction_projects.records.length > 0) {
            auction_projects.records.map((lot) => {
                projectToShow.push({ value: lot.id, show: lot.title })
            })
            setActiveAuctProjects(projectToShow)
        } else {
            setActiveAuctProjects([])
        }
    }, [auction_projects])

    const personalDetailsNew = [
        {
            label: 'Auction Lot Title',
            type: 'text',
            placeholder: 'Enter your Auction Lot Title',
            class: 'col-sm-6 col-12',
            name: 'title',
            formik: formik,
        },
        {
            label: 'Start date',
            placeholder: 'Enter Start date',
            type: 'datetime',
            disablePast: true,
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_added',
            formik: formik,
        },
        {
            label: 'End Date',
            placeholder: 'Enter End Date',
            disablePast: true,
            type: 'datetime',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_closed',
            minDate: dateTimeFormatFunction(formik.values.date_added),
            minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                formik.values.date_added,
            )}`,
            formik: formik,
        },
        {
            label: 'Projects',
            placeholder: 'Projects',
            type: 'multiselect',
            class: 'col-12',
            options: activeAuctProjects,
            name: 'project_id',
            formik: formik,
        },
        {
            label: 'Description',
            placeholder: 'Enter Description',
            type: 'textarea',
            class: 'col-sm-6',
            name: 'description',
            formik: formik,
        },
        {
            label: 'Auction Lot Types',
            placeholder: 'Select Auction Lot Types',
            type: 'select',
            options: auctionlotTypes,
            class: 'col-sm-6',
            name: 'auctionlotTypeId',
            formik: formik,
        },
        {
            label: 'Enable Staggered',
            placeholder: 'Enable Staggered Bidding',
            type: 'checkbox',
            class: 'col-sm-6 col-12',
            name: 'ending_enable',
            formik: formik,
        },
        {
            label: 'Items',
            placeholder: 'Enable Items',
            type: 'number',
            disabled: formik.values.ending_enable ? false : true,
            class: 'col-md-3 col-sm-6 col-12',
            name: 'ending_items',
            formik: formik,
        },
        {
            label: 'Minutes',
            placeholder: 'Enable Minutes',
            type: 'number',
            disabled: formik.values.ending_enable ? false : true,
            class: 'col-md-3 col-sm-6 col-12',
            name: 'ending_mins',
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'avatar',
            titleText: 'Upload auction image',
            innerText: 'Recommended dimensions: 380px * 380px',
            class: 'col-12',
            folder: 'auction',
            multiple: false,
            accept: 'image/*',
        },
    ]

    const formSubmit = (data) => {
        formik.values.market_status = data
        formik.submitForm()
    }

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new' ? 'ADD NEW LOT' : `EDIT LOT: ${single_auction.record.title} `
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <div className="row">{Object.values(mapData(personalDetailsNew))}</div>
                        <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <SecondaryButton
                                onClick={() => {
                                    formSubmit('draft')
                                }}
                                type="button"
                                label="Draft"
                            />
                            <PrimaryButton
                                onClick={() => {
                                    formSubmit('open')
                                }}
                                type="button"
                                label="Submit"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default UserManage
