import React, { useEffect, useContext } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { setAuthToken, setIPAddress } from './custom/common/api'
import { CookiesProvider } from 'react-cookie'
import AuthState from './custom/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import ProductCommonState from './custom/context/common/commonState'
import UserState from './custom/context/user/userState'
import EmployeeState from './custom/context/employee/empState'
import AlertState from './custom/context/alert/alertState'
import CommunicationState from './custom/context/communication/communicationState'
import SettingState from './custom/context/setting/settingState'
import ProductState from './custom/context/product/productState'
import InvoiceState from './custom/context/invoice/invoiceState'
import ReturnState from './custom/context/return/returnState'
import ReportState from './custom/context/report/reportState'
import TransactionState from './custom/context/transaction/transactionState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from './custom/common/alert'
import publicIp from 'public-ip'
import AuctionState from './custom/context/auction/auctionState'
import CartState from './custom/context/cart/cartState'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

const App = () => {
    useEffect(() => {
        async function fetchData() {
            const ipaddress = await publicIp.v4()
            setIPAddress(ipaddress)
        }
        fetchData()
    }, [])

    return (
        <CookiesProvider>
            <CustomCommonState>
                <ProductCommonState>
                    <AuthState>
                        <AlertState>
                            <UserState>
                                <AuctionState>
                                    <ProductState>
                                        <CartState>
                                            <InvoiceState>
                                                <ReturnState>
                                                    <TransactionState>
                                                        <EmployeeState>
                                                            <ReportState>
                                                                <CommunicationState>
                                                                    <SettingState>
                                                                        <MuiPickersUtilsProvider
                                                                            utils={MomentUtils}
                                                                        >
                                                                            <SnackbarProvider
                                                                                maxSnack={3}
                                                                            >
                                                                                <div className="App">
                                                                                    <Alerts />
                                                                                    <Router>
                                                                                        <Routes />
                                                                                    </Router>
                                                                                </div>
                                                                            </SnackbarProvider>
                                                                        </MuiPickersUtilsProvider>
                                                                    </SettingState>
                                                                </CommunicationState>
                                                            </ReportState>
                                                        </EmployeeState>
                                                    </TransactionState>
                                                </ReturnState>
                                            </InvoiceState>
                                        </CartState>
                                    </ProductState>
                                </AuctionState>
                            </UserState>
                        </AlertState>
                    </AuthState>
                </ProductCommonState>
            </CustomCommonState>
        </CookiesProvider>
    )
}

export default App
