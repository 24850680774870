import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../custom/components/templates/Layout'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import '../Dashboard/Dashboard.css'
import './invoice.css'
import CustomTable from '../../custom/components/molecules/CustomTable'
import InvoiceContext from '../../custom/context/invoice/invoiceContext'
import CustomSearch from '../../custom/components/molecules/CustomSearch'
import CustomDialog from '../../custom/components/organisms/Dialog'
import InvoiceView from './view'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'

const Invoice = (props) => {
    const invoiceContext = useContext(InvoiceContext)

    const {
        all_invoices,
        getAllInvoices,
        changeProductStatus,
        responseStatus: responseStatusInvoice,
        clearResponse: clearResponseInvoice,
    } = invoiceContext

    const [changeStatus, setChangeStatus] = React.useState(false)

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        secondaryID: 0,
    })

    const toggleFullScreenPopup = (popup, status, id, secondaryID) => {
        if (popup) {
            setManage({ popup, status, id, secondaryID })
        } else {
            setManage({ popup, status: 'new', id: 0, secondaryID: 0 })
        }
    }

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    let action = props.match.params.action

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            fielddate: 'pc.created_at',
            fromdate: '',
            todate: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            getAllInvoices(values)
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            project_id: '',
            auction_id: '',
        },
        onSubmit: (values) => {
            if (values.action === 'paid' || values.action === 'unpaid') {
                changeProductStatus(values)
            }
        },
    })

    useEffect(() => {
        if (all_invoices) {
            setTableBody(all_invoices.records.length ? all_invoices.records : [])
        }
    }, [all_invoices])

    useEffect(() => {
        setTableBody([])
        formik.values.action = action
        formik.values.page = 1
        formik.values.fromdate = ''
        formik.values.todate = ''
        formik.values.searchterm = ''
        getAllInvoices(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    useEffect(() => {
        console.log('formikSelection.values', formikSelection.values)
    }, [formikSelection.values])

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, type, id, secondaryID)
    }

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.project_id = data
        if (action === 'paid' || action === 'unpaid') {
            setChangeStatus(true)
        }
    }

    const tableValues = [
        {
            field: 'common_invoice',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Invoice ID',
        },
        {
            field: 'total_items',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Items',
        },
        {
            field: 'total_amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Amount',
        },
        {
            field: 'first_name,last_name',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
        {
            field: 'cart_paiddate',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Paid Date',
        },
        {
            field: 'action',
            type: 'button',
            buttonType: 'ViewIcon',
            clickType: 'view',
            clickID: 'common_invoice',
            secondaryClickID: 'user_id',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
        },
    ]

    const tableActions = []

    if (action === 'paid') {
        tableActions.push({
            label: 'Move to Unpaid',
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'unpaid',
        })
    } else if (action === 'unpaid') {
        tableActions.push({
            label: 'Move to Paid',
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'paid',
        })
    } else {
        tableActions.push(
            {
                label: 'Move to Unpaid',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'unpaid',
            },
            {
                label: 'Move to Paid',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'paid',
            },
        )
    }

    const searchInfo = [
        {
            label: 'Invoice Number',
            placeholder: 'Enter invoice number',
            class: 'col-4',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
        {
            label: 'Created From Date',
            placeholder: 'Date Ending from',
            class: 'col-4',
            type: 'date',
            name: 'fromdate',
            formik: formik,
        },
        {
            label: 'Created To Date',
            placeholder: 'Date Ending To',
            class: 'col-4',
            type: 'date',
            name: 'todate',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusInvoice) {
            if (
                responseStatusInvoice.status === 'success' &&
                responseStatusInvoice.from === 'productchange'
            ) {
                setChangeStatus(false)
                getAllInvoices(formik.values)
            }
        }
    }, [responseStatusInvoice])

    return (
        <Layout>
            <div className="dashboard invoice">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_invoices}
                    changeTableFunction={getAllInvoices}
                />
            </div>
            <InvoiceView data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change invoice status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default Invoice
