// for common data
export const COMMON_VALUES = 'COMMON_VALUES'
export const US_STATE_VALUE = 'US_STATE_VALUE'

export const UPLOADED_IMAGE = 'UPLOADED_IMAGE'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_MAIN_DASHBOARD_DATA = 'GET_MAIN_DASHBOARD_DATA'
export const BID_HISTORY_VALUE = 'BID_HISTORY_VALUE'
export const CLEAR_BID_HISTORY = 'CLEAR_BID_HISTORY'
