import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../../custom/components/organisms/FullScreenPopup'
import UserContext from '../../custom/context/user/userContext'
import CommonContext from '../../custom/context/common/commonContext'
import CustomTable from '../../custom/components/molecules/CustomTable'
import CustomSearch from '../../custom/components/molecules/CustomSearch'
import { Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { mapData, handleRedirectInternal } from '../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

const UserManage = (props) => {
    const userContext = useContext(UserContext)
    const classes = useStyles()
    const { search_alldashboardproducts, getDashboardUser } = userContext

    const [tableBody, setTableBody] = useState([])

    const [value, setValue] = React.useState(0)

    const data = props.data
    const toggleFullScreenPopup = props.function

    let action = 'auction'

    const formik = useFormik({
        initialValues: {
            limit: 20,
            action: action,
            page: 1,
            orderby: 'p.date_closed,asc',
            order: '',
            user_id: 0,
            filters: {
                category: {
                    value: [],
                    type: 'array',
                    field: 'it.categoryTypeId',
                },
                condition: {
                    value: [],
                    type: 'array',
                    field: 'it.conditionTypeId',
                },
                location: {
                    value: [],
                    type: 'array',
                    field: 'p.location_id',
                },
                price: {
                    value: '',
                    type: 'greaterequal',
                    field: 'p.wprice',
                },
                searchbar: {
                    value: '',
                    type: 'like',
                    field: 'p.title',
                },
                auctionid: {
                    value: [],
                    type: 'array',
                    field: 'p.auctionlotId',
                },
            },
        },
        onSubmit: (values) => {
            getDashboardUser(values)
        },
    })

    useEffect(() => {
        if (data.status === 'view') {
            formik.values.user_id = data.id
            getDashboardUser(formik.values)
        }
    }, [data.id])

    useEffect(() => {
        if (search_alldashboardproducts) {
            setTableBody(
                search_alldashboardproducts.records.length
                    ? search_alldashboardproducts.records
                    : [],
            )
        }
    }, [search_alldashboardproducts])

    const searchInfo = []

    const tableValues = [
        {
            field: 'avatarorg',
            type: 'image',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Picture',
        },
        {
            field: 'title',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Title',
        },
        {
            field: 'date_closed',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Close Date',
        },
        {
            field: 'bid_count',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Bid count',
        },
        {
            field: 'market_status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
    ]
    if (action === 'won') {
        tableValues.push({
            field: 'buynowamount',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Won Price',
        })
    } else {
        tableValues.push({
            field: 'wprice',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Current Price',
        })
    }

    const tableActions = []

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            formik.setFieldValue('action', 'auction')
            formik.values.action = 'auction'
        } else if (newValue === 1) {
            formik.setFieldValue('action', 'winning')
            formik.values.action = 'winning'
        } else if (newValue === 2) {
            formik.setFieldValue('action', 'outbid')
            formik.values.action = 'outbid'
        } else if (newValue === 3) {
            formik.setFieldValue('action', 'watchlist')
            formik.values.action = 'watchlist'
        } else if (newValue === 4) {
            formik.setFieldValue('action', 'won')
            formik.values.action = 'won'
        }
        getDashboardUser(formik.values)
        setValue(newValue)
    }

    return (
        <FullScreenPopup
            modaltitle={'USER DASHBOARD'}
            open={data.viewpopup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <div className="dashboard userManagement">
                        <CustomSearch searchInfo={searchInfo} formik={formik} />
                        <div className="d-flex justify-content-between align-items-center mt-4"></div>
                        <div className={classes.root}>
                            <AppBar position="static">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="simple tabs example"
                                >
                                    <Tab label="All" {...a11yProps(0)} />
                                    <Tab label="Winning" {...a11yProps(1)} />
                                    <Tab label="Outbid" {...a11yProps(2)} />
                                    <Tab label="Watchlist" {...a11yProps(3)} />
                                    <Tab label="Won" {...a11yProps(4)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={value} index={0}>
                                <CustomTable
                                    formik={formik.values}
                                    tableBody={tableBody}
                                    tableValues={tableValues}
                                    tableActions={tableActions}
                                    allData={search_alldashboardproducts}
                                    changeTableFunction={getDashboardUser}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <CustomTable
                                    formik={formik.values}
                                    tableBody={tableBody}
                                    tableValues={tableValues}
                                    tableActions={tableActions}
                                    allData={search_alldashboardproducts}
                                    changeTableFunction={getDashboardUser}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <CustomTable
                                    formik={formik.values}
                                    tableBody={tableBody}
                                    tableValues={tableValues}
                                    tableActions={tableActions}
                                    allData={search_alldashboardproducts}
                                    changeTableFunction={getDashboardUser}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <CustomTable
                                    formik={formik.values}
                                    tableBody={tableBody}
                                    tableValues={tableValues}
                                    tableActions={tableActions}
                                    allData={search_alldashboardproducts}
                                    changeTableFunction={getDashboardUser}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <CustomTable
                                    formik={formik.values}
                                    tableBody={tableBody}
                                    tableValues={tableValues}
                                    tableActions={tableActions}
                                    allData={search_alldashboardproducts}
                                    changeTableFunction={getDashboardUser}
                                />
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default UserManage
