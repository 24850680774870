import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../custom/components/templates/Layout'
import CustomTable from '../../custom/components/molecules/CustomTable'
import CustomSearch from '../../custom/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import AlertContext from '../../custom/context/alert/alertContext'
import UserContext from '../../custom/context/user/userContext'
import { handleRedirectInternal } from '../../custom/common/components'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import CustomDialog from '../../custom/components/organisms/Dialog'
import '../Dashboard/Dashboard.css'
import { Button } from '@material-ui/core'

import UserManage from './UserManage'
import UserDashboard from './UserDashboard'

const UserView = (props) => {
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    const history = useHistory()
    const { setAlert } = alertContext
    const {
        all_users,
        changeUserStatus,
        getAllUsers,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = userContext
    const [changeStatus, setChangeStatus] = React.useState(false)
    const [manage, setManage] = React.useState({
        popup: false,
        viewpopup: false,
        status: 'new',
        id: 0,
    })
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            if (status === 'view') {
                setManage({ viewpopup: popup, popup: false, status, id })
            } else {
                setManage({ viewpopup: false, popup, status, id })
            }
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    let action = props.match.params.action !== 'all' ? props.match.params.action : null

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action ? action.toString() : null,
                    type: 'in',
                    field: 'u.status',
                },
                first_name: {
                    value: null,
                    type: 'like',
                    field: 'u.first_name',
                },
                last_name: {
                    value: null,
                    type: 'like',
                    field: 'u.last_name',
                },
                email: {
                    value: null,
                    type: 'like',
                    field: 'u.email',
                },
            },
        },
        onSubmit: (values) => {
            getAllUsers(values)
        },
    })

    useEffect(() => {
        if (all_users) {
            setTableBody(all_users.records.length ? all_users.records : [])
        }
    }, [all_users])

    useEffect(() => {
        setTableBody([])
        formik.values.filters.action.value = action
        formik.values.page = 1
        formik.values.email = ''
        formik.values.first_name = ''
        formik.values.last_name = ''

        getAllUsers(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    const onClickEdit = (id, secondaryID, type) => {
        if (type === 'view') {
            toggleFullScreenPopup(true, 'view', id)
        } else {
            toggleFullScreenPopup(true, 'edit', id)
        }
    }
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            user_id: '',
        },
        onSubmit: (values) => {
            if (
                values.action === 'active' ||
                values.action === 'deactivate' ||
                values.action === 'not_bid'
            ) {
                changeUserStatus(values)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.user_id = data
        if (action === 'active' || action === 'deactivate' || action === 'not_bid') {
            setChangeStatus(true)
        }
    }

    const tableValues = [
        {
            field: 'first_name',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'First Name',
        },
        {
            field: 'last_name',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Last Name',
        },
        {
            field: 'email',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            field: 'last_login',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Last Login',
        },
        {
            field: 'status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'view',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: '',
            buttonType: 'visibility',
        },
    ]

    const searchInfo = [
        {
            label: 'Email',
            placeholder: 'Enter Email',
            class: 'col-4',
            type: 'text',
            filter: true,
            name: 'email',
            formik: formik,
        },
        {
            label: 'First Name',
            placeholder: 'Enter First Name',
            class: 'col-4',
            type: 'text',
            filter: true,
            name: 'first_name',
            formik: formik,
        },
        {
            label: 'Last Name',
            placeholder: 'Enter Last Name',
            class: 'col-4',
            type: 'text',
            filter: true,
            name: 'last_name',
            formik: formik,
        },
    ]

    const tableActions = []

    if (action === 'active') {
        tableActions.push(
            {
                label: 'Move to Not Bid Status',
                icon: <span className="material-icons">warning</span>,
                onclick: onSelectMultiProducts,
                type: 'not_bid',
            },
            {
                label: 'Move to Deactivated Status',
                icon: <span className="material-icons">block</span>,
                onclick: onSelectMultiProducts,
                type: 'deactivate',
            },
        )
        tableActions.push()
    } else if (action === 'deactivate') {
        tableActions.push(
            {
                label: 'Move to Active Status',
                icon: <span className="material-icons">check_circle</span>,
                onclick: onSelectMultiProducts,
                type: 'active',
            },
            {
                label: 'Move to Not Bid Status',
                icon: <span className="material-icons">warning</span>,
                onclick: onSelectMultiProducts,
                type: 'not_bid',
            },
        )
    } else if (action === 'not_bid') {
        tableActions.push(
            {
                label: 'Move to Active Status',
                icon: <span className="material-icons">check_circle</span>,
                onclick: onSelectMultiProducts,
                type: 'active',
            },
            {
                label: 'Move to Deactivated Status',
                icon: <span className="material-icons">block</span>,
                onclick: onSelectMultiProducts,
                type: 'deactivate',
            },
        )
    } else {
        tableActions.push(
            {
                label: 'Move to Active Status',
                icon: <span className="material-icons">check_circle</span>,
                onclick: onSelectMultiProducts,
                type: 'active',
            },
            {
                label: 'Move to Not Bid Status',
                icon: <span className="material-icons">warning</span>,
                onclick: onSelectMultiProducts,
                type: 'not_bid',
            },
            {
                label: 'Move to Deactivated Status',
                icon: <span className="material-icons">block</span>,
                onclick: onSelectMultiProducts,
                type: 'deactivate',
            },
        )
    }

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'changeStatus') {
                    setChangeStatus(false)
                    getAllUsers(formik.values)
                }
                if (
                    responseStatusUser.from === 'userAction' ||
                    responseStatusUser.from === 'resendVerificationEmail'
                ) {
                    toggleFullScreenPopup(false)
                    getAllUsers(formik.values)
                }
            }
        }
    }, [responseStatusUser])

    return (
        <Layout>
            <div className="dashboard userManagement">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">person_add_alt_1</span>Add New User
                    </Button>
                    <Button>
                        <span className="material-icons">get_app</span>Export All Data
                    </Button>
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_users}
                    changeTableFunction={getAllUsers}
                />
            </div>

            <UserManage data={manage} function={toggleFullScreenPopup} />
            <UserDashboard data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change user status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status?</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default UserView
