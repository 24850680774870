import React, { useContext, useEffect } from 'react'
import { LOGO, SITE_NAME } from '../../../../utils/UI'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import './Header.css'
import { Link } from 'react-router-dom'
import AuthContext from '../../../../custom/context/auth/authContext'
import AlertContext from '../../../../custom/context/alert/alertContext'
import SettingContext from '../../../../custom/context/setting/settingContext'
import CommunicationContext from '../../../../custom/context/communication/communicationContext'
import CartContext from '../../../../custom/context/cart/cartContext'
import UserContext from '../../../../custom/context/user/userContext'
import AuctionContext from '../../../../custom/context/auction/auctionContext'
import ProductContext from '../../../../custom/context/product/productContext'
import { handleRedirectInternal } from '../../../../custom/common/components'
import EmployeeContext from '../../../../custom/context/employee/empContext'
import BidHistory from '../../../components/organisms/BidHistory'

const Header = () => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const communicationContext = useContext(CommunicationContext)
    const settingContext = useContext(SettingContext)
    const cartContext = useContext(CartContext)

    const userContext = useContext(UserContext)
    const employeeContext = useContext(EmployeeContext)
    const auctionContext = useContext(AuctionContext)
    const productContext = useContext(ProductContext)

    const { logout } = authContext
    const { setAlert } = alertContext

    const {
        responseStatus: responseStatusCommuncation,
        clearResponse: clearResponseCommuncation,
    } = communicationContext

    const {
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingContext

    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext

    const {
        responseStatus: responseStatusEmployee,
        clearResponse: clearResponseEmployee,
    } = employeeContext

    const {
        responseStatus: responseStatusAuction,
        clearResponse: clearResponseAuction,
    } = auctionContext

    const {
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = productContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    useEffect(() => {
        if (responseStatusCommuncation) {
            setAlert(responseStatusCommuncation.message, responseStatusCommuncation.status)
            clearResponseCommuncation()
        }
        if (responseStatusUser) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusEmployee) {
            setAlert(responseStatusEmployee.message, responseStatusEmployee.status)
            clearResponseEmployee()
        }
        if (responseStatusAuction) {
            setAlert(responseStatusAuction.message, responseStatusAuction.status)
            clearResponseAuction()
        }
        if (responseStatusProduct) {
            setAlert(responseStatusProduct.message, responseStatusProduct.status)
            clearResponseProduct()
        }
        if (responseStatusSetting) {
            setAlert(responseStatusSetting.message, responseStatusSetting.status)
            clearResponseSetting()
        }
        if (responseStatusCart) {
            setAlert(responseStatusCart.message, responseStatusCart.status)
            clearResponseCart()
        }
    }, [
        responseStatusCommuncation,
        responseStatusUser,
        responseStatusEmployee,
        responseStatusAuction,
        responseStatusProduct,
        responseStatusSetting,
        responseStatusCart,
    ])

    const history = useHistory()

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
    }

    return (
        <div className="header">
            <div className="customContainer d-flex justify-content-between align-items-center">
                <div className="headLogo">
                    <Link to="/dashboard">
                        <img src={LOGO} alt={`${SITE_NAME} Logo`} />
                    </Link>
                </div>
                <div>
                    <Button
                        onClick={() => onLogOut()}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                    >
                        Logout
                        <span className="material-icons">power_settings_new</span>
                    </Button>
                </div>
            </div>
            <BidHistory />
        </div>
    )
}

export default Header
