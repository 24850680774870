import { Button } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { currencyFormat } from '../../custom/common/components'

const CartItem = (props) => {
    const [product, setProduct] = useState()
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])
    return (
        <>
            {product ? (
                <>
                    <div className="cartItem d-flex justify-content-between align-items-start">
                        <div className="itemInfo d-flex justify-content-start">
                            <div className="pclImg">
                                <img src={product.avatarorg} alt={product.title} />
                            </div>
                            <div className="listContent">
                                <h2 className="listProdTitle">{product.title}</h2>
                                <div className="listLotInfo d-flex justify-content-start align-items-center">
                                    <h5>Lot # {product.item_id}</h5>
                                </div>
                            </div>
                        </div>

                        <div className="listActBtn">
                            <div className="listBidInfo d-flex justify-content-between align-items-center">
                                <p className="d-flex justify-content-center align-items-center">
                                    <span>{currencyFormat(product.buynowamount)}</span>
                                    {props.from == 'cart' ? (
                                        <>
                                            <span className="listDivider">|</span>
                                            <span>Unpaid</span>
                                        </>
                                    ) : null}
                                </p>
                            </div>
                            {props.from == 'cart' ? (
                                <>
                                    {product.cart_id === 0 ? (
                                        <Button
                                            className="removeCart"
                                            onClick={() =>
                                                props.changeOnCart({ cart: 1, id: product.id })
                                            }
                                        >
                                            Add to cart
                                        </Button>
                                    ) : (
                                        <Button
                                            className="removeCart"
                                            onClick={() =>
                                                props.changeOnCart({ cart: 0, id: product.id })
                                            }
                                        >
                                            Remove from cart
                                        </Button>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}
export default CartItem
