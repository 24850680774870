import React, { useEffect, useContext } from 'react'
import '../Dashboard/Dashboard.css'
import RadioBox from '../../custom/components/atoms/RadioBox'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { FormControlLabel, ListItem } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { mapData, dateFormatFunction, handleRedirectInternal } from '../../custom/common/components'
import ReportDisplay from './display'
import { useHistory } from 'react-router-dom'

import CheckBox from '../../custom/components/atoms/CheckBox'
import Layout from '../../custom/components/templates/Layout'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import ReportContext from '../../custom/context/report/reportContext'

const ReportManagement = () => {
    const history = useHistory()
    const reportContext = useContext(ReportContext)
    const [resetForm, setResetForm] = React.useState(false)
    const [reportsData, setReportsData] = React.useState(null)

    const [manage, setManage] = React.useState({
        popup: false,
    })

    const toggleFullScreenPopup = (popup) => {
        if (popup) {
            setManage({ popup })
        } else {
            setManage({ popup })
        }
    }

    const {
        reports_data,
        getAllReportDisplay,
        getAllReportDownload,
        responseStatus: responseStatusReport,
        clearResponse: clearResponseReport,
    } = reportContext

    const allSortOptions = [
        { id: 'asc', description: 'Ascending' },
        { id: 'desc', description: 'Descending' },
    ]

    const allSearchOptions = [
        { id: 'within', description: 'Within' },
        { id: 'customSelection', description: 'Custom Selection' },
    ]

    const validation = Yup.object({
        order: Yup.string().required('Required!'),
        action: Yup.string().required('Required!'),
        report: Yup.string().required('Required!'),
        search: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            action: 'display',
            order: 'asc',
            orderby: '',
            search: 'within',
            report: 'active_user_management',
            within: 'day',
            page: 1,
            limit: 20,
            start_date: dateFormatFunction(new Date()),
            end_date: dateFormatFunction(new Date()),
            limit_results: '',
            searchterm: '',
            fields: [],
        },
        validateOnBlur: false,
        validationSchema: validation,
        onSubmit: (values) => {
            formik.setSubmitting(true)
            if (values.action === 'display') {
                getAllReportDisplay(values)
            } else {
                getAllReportDownload(values)
            }
        },
    })

    useEffect(() => {
        if (reports_data) {
            setReportsData(reports_data)
            console.log('reports_data', reports_data)
            toggleFullScreenPopup(true)
        }
    }, [reports_data])

    const action = [
        {
            label: 'Action',
            placeholder: 'Enter action',
            class: 'col-12',
            type: 'select',
            options: [
                { value: 'display', show: 'Display' },
                { value: 'download', show: 'Download' },
            ],
            name: 'action',
            formik: formik,
        },
    ]

    const withinRange = [
        {
            label: 'Within',
            placeholder: 'Enter Within',
            class: 'col-12',
            type: 'select',
            options: [
                { value: 'day', show: 'The Past Day' },
                { value: 'week', show: 'The Past Week' },
                { value: 'month', show: 'The Past Month' },
                { value: 'year', show: 'The Past Year' },
            ],
            name: 'within',
            formik: formik,
        },
    ]

    const report = [
        {
            label: 'Report',
            placeholder: 'Enter Report',
            class: 'col-12',
            type: 'select',
            groupoptions: [
                {
                    head: 'User management',
                    options: [
                        { value: 'active_user_management', show: 'Active' },
                        { value: 'deactivate_user_management', show: 'Deactive' },
                    ],
                },
                {
                    head: 'Product management',
                    options: [
                        { value: 'open_product_management', show: 'Open' },
                        { value: 'ending_product_management', show: 'Ending' },
                        { value: 'closed_product_management', show: 'Closed' },
                        { value: 'sold_product_management', show: 'Sold' },
                    ],
                },
            ],
            name: 'report',
            formik: formik,
        },
    ]
    const dateRange = [
        {
            label: 'From date',
            placeholder: 'Enter From date',
            type: 'date',
            class: 'col-6',
            name: 'start_date',
            formik: formik,
        },
        {
            label: 'End date',
            placeholder: 'Enter End date',
            type: 'date',
            class: 'col-6',
            name: 'end_date',
            formik: formik,
        },
    ]
    const limitResult = [
        {
            label: 'Limit results',
            placeholder: 'Enter Limit results',
            type: '',
            class: 'col-12',
            name: 'limit_results',
            formik: formik,
        },
    ]

    let fieldsToShow = []

    fieldsToShow = [
        {
            field: 'id',
            label: 'User ID',
            sort: true,
            type: '',
            group: ['active_user_management', 'deactivate_user_management'],
        },
        {
            field: 'user_fullname',
            label: 'User Name',
            type: '',
            group: ['active_user_management', 'deactivate_user_management'],
        },
        {
            field: 'email',
            label: 'User Email',
            sort: true,
            type: '',
            group: ['active_user_management', 'deactivate_user_management'],
        },
        {
            field: 'phone',
            label: 'Phone Number',
            type: '',
            group: ['active_user_management', 'deactivate_user_management'],
        },
        {
            field: 'created_at',
            label: 'Created Date',
            sort: true,
            type: 'datetime',
            group: ['active_user_management', 'deactivate_user_management'],
        },
        {
            field: 'last_login',
            label: 'Last Login',
            sort: true,
            type: 'datetime',
            group: ['active_user_management', 'deactivate_user_management'],
        },
        {
            field: 'status',
            label: 'Status',
            type: 'capitalize',
            group: ['active_user_management', 'deactivate_user_management'],
        },
        {
            field: 'id',
            label: 'ID',
            sort: true,
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'title',
            label: 'Title',
            sort: true,
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'date_added',
            label: 'Starting Date',
            sort: true,
            type: 'datetime',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'date_closed',
            label: 'End Date',
            sort: true,
            type: 'datetime',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'sprice',
            label: 'Starting Price',
            type: 'price',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'rprice',
            label: 'Reserve Price',
            type: 'price',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'buynowamount',
            label: 'Sold Price',
            type: 'price',
            group: ['sold_product_management'],
        },
        {
            field: 'wprice',
            label: 'Current Price',
            sort: true,
            type: 'price',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
            ],
        },
        {
            field: 'market_status',
            label: 'Status',
            sort: true,
            type: 'capitalize',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'totalbids',
            label: 'No. of Bids',
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'numDiffBidders',
            label: 'No. of Different Bidders',
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'winnerName',
            label: 'Buyer Name',
            type: '',
            group: ['sold_product_management'],
        },
        {
            field: 'winemail',
            label: 'Winner Email',
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
            ],
        },
        {
            field: 'buynowemail',
            label: 'Buyer Email',
            type: '',
            group: ['sold_product_management'],
        },
        {
            field: 'common_invoice',
            label: 'Invoice ID',
            sort: true,
            type: '',
            group: ['sold_product_management'],
        },
        {
            field: 'paid_date',
            label: 'Paid Date',
            sort: true,
            type: 'datetime',
            group: ['sold_product_management'],
        },
    ]

    useEffect(() => {
        if (formik.values.report) {
            formik.values.page = 1
            formik.values.order = 'asc'
            formik.values.orderby = ''
            formik.values.searchterm = ''
            let valueSelected = []
            let fieldsToSort = fieldsToShow
            fieldsToSort
                .filter((field) => field.group.indexOf(formik.values.report.toString()) !== -1)
                .map((d, i) => valueSelected.push(d.field))
            formik.values.fields = valueSelected
            setResetForm(!resetForm)
            setReportsData(null)
        }
        return () => {
            setReportsData(null)
        }
    }, [formik.values.report])

    return (
        <Layout>
            <div className="dashboard reportManagement">
                <ReportDisplay
                    formik={formik}
                    fieldsToShow={fieldsToShow}
                    data={manage}
                    function={toggleFullScreenPopup}
                />
                <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                    <h6 className="dashSubtitle">
                        Report management gives you direct access of accounting overview based on
                        the reporting you wish to formulate.
                    </h6>
                    <div className="reportAction d-flex align-items-center">
                        <div className="row">{Object.values(mapData(action))}</div>
                    </div>

                    <div className="reportAction d-flex align-items-center">
                        <div className="row">{Object.values(mapData(report))}</div>
                    </div>

                    <h6 className="dashSubtitle">
                        Customize your report using a wide variety of date range options.
                    </h6>

                    <div className="reportAction d-flex align-items-center">
                        <div className="reportLabel">
                            <h6>Fields</h6>
                        </div>

                        <div className="reportFields d-flex justify-content-start align-items-center flex-wrap">
                            {fieldsToShow
                                .filter(
                                    (field) =>
                                        field.group.indexOf(formik.values.report.toString()) !== -1,
                                )
                                .map((d, i) => (
                                    <>
                                        <CheckBox
                                            name="fields"
                                            label={d.label}
                                            checked={
                                                formik.values.fields.indexOf(d.field.toString()) !==
                                                -1
                                                    ? true
                                                    : false
                                            }
                                            value={d.field.toString()}
                                            onChange={formik.handleChange}
                                        />
                                    </>
                                ))}
                        </div>
                    </div>

                    <div className="reportAction d-flex align-items-center">
                        <div className="reportLabel">
                            <h6>Search</h6>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <RadioBox
                                items={allSearchOptions}
                                value={formik.values.search}
                                onChange={formik.handleChange}
                                name="search"
                            />
                        </div>
                        <div className="reportRange datePicker d-flex align-items-center">
                            {formik.values.search === 'within' ? (
                                <>
                                    <div className="row">{Object.values(mapData(withinRange))}</div>
                                </>
                            ) : (
                                <>
                                    <div className="row">{Object.values(mapData(dateRange))}</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="reportAction d-flex align-items-center">
                        <div className="reportLabel">
                            <h6>Order Results</h6>
                        </div>
                        <div className="orderResults d-flex justify-content-center align-items-center">
                            <RadioBox
                                items={allSortOptions}
                                value={formik.values.order}
                                onChange={formik.handleChange}
                                name="order"
                            />
                        </div>
                    </div>

                    {/* <div className="reportAction d-flex align-items-center">
                        <div className="reportLabel">
                            <h6>Limit Results</h6>
                        </div>
                        <div className="limitResults">
                            <div className="row">{Object.values(mapData(limitResult))}</div>
                        </div>
                    </div> */}

                    <div className="actionButton">
                        <PrimaryButton type="submit" label="Generate Report" />
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default ReportManagement
