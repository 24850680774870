import React, { useReducer } from 'react'
import AuthContext from './authContext'
import AuthReducer from './authReducer'
import { apiCall, setAuthToken } from '../../common/api'
import { response } from '../common'
import { USER_LOADED, LOGOUT, RESPONSE_STATUS, CLEAR_RESPONSE } from './authTypes'

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: null,
        isAdmin: null,
        loading: true,
        user: {},
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    // Register User
    const register = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'register', formData, '', '')])
            resp.commonResponse(res.data, 'register')
        } catch (err) {
            resp.commonErrorResponse('register')
        }
    }

    // Check Email User
    const checkValidation = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkValidation', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'checkValidation')
        } catch (err) {
            resp.commonErrorResponse('checkValidation')
        }
    }

    // Login User
    const login = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'login', formData, '', '')])
            resp.commonResponse(res.data, 'login')
            if (res.data.status === 'success') {
                localStorage.setItem('token', res.data.data.token)
                loadUser()
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    // Load User
    const loadUser = async () => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        const [res] = await Promise.all([apiCall('post', 'validateUser', {}, '', '')])
        if (res.data.status === 'success') {
            dispatch({
                type: USER_LOADED,
                payload: {
                    data: res.data.data.responseData,
                },
            })
        } else if (res.data.status === 'error') {
            dispatch({
                type: LOGOUT,
            })
        } else {
            dispatch({
                type: LOGOUT,
            })
        }
    }

    // Log out
    const logout = () =>
        dispatch({
            type: LOGOUT,
        })

    // Clear Response
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                isAdmin: state.isAdmin,
                loading: state.loading,
                user: state.user,
                responseStatus: state.responseStatus,
                register,
                checkValidation,
                login,
                logout,
                loadUser,
                clearResponse,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState
