import React, { useReducer } from 'react'
import SettingContext from './settingContext'
import SettingReducer from './settingReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_STATIC_PAGES,
    GET_SINGLE_STATIC_PAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_BID_INCREMENTS,
    GET_SINGLE_BID_INCREMENT,
    GET_ALL_BANNERS,
} from './settingTypes'

const SettingState = (props) => {
    const initialState = {
        static_pages: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_static_page: {
            record: {},
        },
        bid_increments: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_bid_increment: {
            record: {},
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(SettingReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllStaticPages = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'configurationVariables', formData, '', 'setting'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_STATIC_PAGES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleStaticPage = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleConfiguration', formData, '', 'setting'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_STATIC_PAGE,
                    payload: {
                        record: res.data.data.responseData.item
                            ? res.data.data.responseData.item
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const staticPageAction = async (formData) => {
        try {
            const from = 'CommunicationStaticPages'
            const [res] = await Promise.all([
                apiCall('post', 'configurationAction', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllBidIncrements = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAllBidIncrements', formData, '', 'setting'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BID_INCREMENTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleBidIncrement = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleBidIncrement', formData, '', 'setting'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_BID_INCREMENT,
                    payload: {
                        record: res.data.data.responseData.item
                            ? res.data.data.responseData.item
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const bidIncrementAction = async (formData) => {
        try {
            const from = 'CommunicationStaticPages'
            const [res] = await Promise.all([
                apiCall('post', 'bidIncrementAction', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const deleteBidIncrement = async (formData) => {
        try {
            const from = 'CommunicationStaticPages'
            const [res] = await Promise.all([
                apiCall('post', 'deleteBidIncrement', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const searchTableAction = async (formData) => {
        try {
            const from = 'CommunicationSearchTable'
            const [res] = await Promise.all([
                apiCall('post', 'searchTableAction', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllBanners = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAllBanners', formData, '', 'setting'),
            ])
            const from = 'getAllBanners'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BANNERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const uploadBanner = async (formData) => {
        try {
            const from = 'updateBanners'
            const [res] = await Promise.all([
                apiCall('post', 'updateBanners', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <SettingContext.Provider
            value={{
                responseStatus: state.responseStatus,
                single_static_page: state.single_static_page,
                static_pages: state.static_pages,
                bid_increments: state.bid_increments,
                single_bid_increment: state.single_bid_increment,
                banners: state.banners,
                clearResponse,
                getAllStaticPages,
                getSingleStaticPage,
                staticPageAction,
                getAllBidIncrements,
                getSingleBidIncrement,
                bidIncrementAction,
                deleteBidIncrement,
                searchTableAction,
                uploadBanner,
                getAllBanners,
            }}
        >
            {props.children}
        </SettingContext.Provider>
    )
}

export default SettingState
