import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import SecondaryButton from '../../custom/components/atoms/SecondaryButton'
import FullScreenPopup from '../../custom/components/organisms/FullScreenPopup'
import { mapData, handleRedirectInternal } from '../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommunicationContext from '../../custom/context/communication/communicationContext'

const TemplateManage = (props) => {
    const communicationContext = useContext(CommunicationContext)

    const { single_static_page, getSingleStaticPage, staticPageAction } = communicationContext

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        content: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: '',
            content: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            staticPageAction(values)
        },
    })

    useEffect(() => {
        resetFrom()
    }, [props])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleStaticPage(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        console.log('single_static_page', single_static_page)
        if (single_static_page.record && data.status === 'edit') {
            formik.values.id = data.id
            const template = single_static_page.record
            formik.values.name = template.name
            formik.values.content = template.content
        } else {
            resetFrom()
        }
    }, [single_static_page])

    const resetFrom = () => {
        formik.values.id = 0
        formik.values.name = ''
        formik.values.content = ''
        formik.handleReset()
    }

    const emailTemplate = []

    if (data.status === 'new') {
        emailTemplate.push({
            label: 'Title',
            placeholder: 'Enter Title',
            class: 'col-12 mb-4',
            type: 'text',
            shrink: true,
            name: 'name',
            formik: formik,
        })
    }
    emailTemplate.push({
        class: 'col-12',
        name: 'content',
        type: 'ckeditor',
        formik: formik,
    })

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW STATIC PAGE'
                    : `EDIT STATIC PAGE: ${single_static_page.record.name}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <div className="row">{Object.values(mapData(emailTemplate))}</div>

                        <div className="actionButton d-flex justify-content-center align-items-center">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default TemplateManage
