import React, { useReducer } from 'react'
import TransactionContext from './transactionContext'
import TransactionReducer from './transactionReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_PAYMENT_LOGS,
    GET_ALL_REFUND_LOGS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_RECEIPTS,
} from './transactionTypes'

const TransactionState = (props) => {
    const initialState = {
        all_payment: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_refund: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        receipts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(TransactionReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllPayment = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'payment', formData, '', 'transaction'),
            ])
            const from = 'Invoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PAYMENT_LOGS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllRefund = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'refund', formData, '', 'transaction'),
            ])
            const from = 'Invoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_REFUND_LOGS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllReceipts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'receipts', formData, '', 'transaction'),
            ])
            const from = 'Recetips'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_RECEIPTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <TransactionContext.Provider
            value={{
                all_payment: state.all_payment,
                all_refund: state.all_refund,
                receipts: state.receipts,
                responseStatus: state.responseStatus,
                clearResponse,
                getAllPayment,
                getAllRefund,
                getAllReceipts,
            }}
        >
            {props.children}
        </TransactionContext.Provider>
    )
}

export default TransactionState
