import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../custom/components/templates/Layout'
import CustomTable from '../../custom/components/molecules/CustomTable'
import CustomSearch from '../../custom/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import EmployeeContext from '../../custom/context/employee/empContext'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import CustomDialog from '../../custom/components/organisms/Dialog'
import '../Dashboard/Dashboard.css'
import { Button } from '@material-ui/core'

import EmployeeManage from './view'

const EmployeeView = (props) => {
    const employeeContext = useContext(EmployeeContext)
    const {
        all_employees,
        changeUserStatus,
        getAllEmployees,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = employeeContext
    const [changeStatus, setChangeStatus] = React.useState(false)
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    let action = props.match.params.action

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            action: action,
            emp_email: '',
            emp_firstname: '',
            emp_lastname: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            getAllEmployees(values)
        },
    })

    useEffect(() => {
        if (all_employees) {
            setTableBody(all_employees.records.length ? all_employees.records : [])
        }
    }, [all_employees])

    useEffect(() => {
        setTableBody([])
        formik.values.action = action
        formik.values.page = 1
        formik.values.emp_email = ''
        formik.values.emp_firstname = ''
        formik.values.emp_lastname = ''

        getAllEmployees(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            user_id: '',
        },
        onSubmit: (values) => {
            if (values.action === 'active' || values.action === 'deactivate') {
                changeUserStatus(values)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.user_id = data
        if (action === 'active' || action === 'deactivate') {
            setChangeStatus(true)
        }
    }

    const tableValues = [
        {
            field: 'emp_firstname',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'First Name',
        },
        {
            field: 'emp_lastname',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Last Name',
        },
        {
            field: 'emp_email',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            field: 'emp_status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'emp_type',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
        },
    ]

    const searchInfo = [
        {
            label: 'Email',
            placeholder: 'Enter Email',
            class: 'col-4',
            type: 'text',
            name: 'emp_email',
            formik: formik,
        },
        {
            label: 'First Name',
            placeholder: 'Enter First Name',
            class: 'col-4',
            type: 'text',
            name: 'emp_firstname',
            formik: formik,
        },
        {
            label: 'Last Name',
            placeholder: 'Enter Last Name',
            class: 'col-4',
            type: 'text',
            name: 'emp_lastname',
            formik: formik,
        },
    ]

    const tableActions = []

    if (action === 'active') {
        tableActions.push({
            label: 'Move to De-activate',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactivate',
        })
    } else if (action === 'deactivate') {
        tableActions.push({
            label: 'Move to Activate',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        })
    } else {
        tableActions.push(
            {
                label: 'Move to De-activate',
                icon: <span className="material-icons">block</span>,
                onclick: onSelectMultiProducts,
                type: 'deactivate',
            },
            {
                label: 'Move to Activate',
                icon: <span className="material-icons">check_circle</span>,
                onclick: onSelectMultiProducts,
                type: 'active',
            },
        )
    }

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'changeStatus') {
                    setChangeStatus(false)
                    getAllEmployees(formik.values)
                }
                if (responseStatusUser.from === 'employeeAction') {
                    toggleFullScreenPopup(false)
                    getAllEmployees(formik.values)
                }
            }
        }
    }, [responseStatusUser])

    return (
        <Layout>
            <div className="dashboard employeeManagement">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">person_add_alt_1</span>Add New Employee
                    </Button>
                    <Button>
                        <span className="material-icons">get_app</span>Export All Data
                    </Button>
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_employees}
                    changeTableFunction={getAllEmployees}
                />
            </div>

            <EmployeeManage data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change user status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default EmployeeView
