import React, { useReducer } from 'react'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    COMMON_VALUES,
    US_STATE_VALUE,
    UPLOADED_IMAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_MAIN_DASHBOARD_DATA,
    BID_HISTORY_VALUE,
    CLEAR_BID_HISTORY,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allCategory: [],
        allLocations: [],
        allCondition: [],
        allDamageTypes: [],
        allPackageTypes: [],
        allBidIncrements: [],
        allNotifications: [],
        configVariables: {},
        USStates: [],
        uploadedFiles: [],
        mainDashboardData: {},
        bidHistoryValue: 0,
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common', false)])
        console.log('res.data.data.responseData', res.data.data.responseData)
        if (res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategoryTypes,
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allLocations: res.data.data.responseData.tableValues.alllocations,
                    allCondition: res.data.data.responseData.tableValues.allconditionTypes,
                    allDamageTypes: res.data.data.responseData.tableValues.alldamageTypes,
                    allPackageTypes: res.data.data.responseData.tableValues.allpackageTypes,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    configVariables: res.data.data.responseData.variables,
                    allPrograms: res.data.data.responseData.programs,
                    allItemStatus: res.data.data.responseData.itemStatus,
                },
            })
        }
    }

    const uploadImage = async (formData, fromUpload) => {
        try {
            const from = 'productchange'
            const [res] = await Promise.all([
                apiCall('post', 'muliple_img_uploader', formData, '', 'image_uploader', true),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: UPLOADED_IMAGE,
                    payload: {
                        files: res.data.data.responseData.files,
                        from: fromUpload,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const resetDropZone = (fromUpload) => {
        dispatch({
            type: UPLOADED_IMAGE,
            payload: {
                files: [],
                from: fromUpload,
            },
        })
    }

    const deleteImage = (file, fromUpload) => {
        resetDropZone(fromUpload)
        //Implement the removal of image api
    }

    const setUSState = async (data) => {
        dispatch({
            type: US_STATE_VALUE,
            payload: data,
        })
    }

    const getMainDashboardData = async () => {
        try {
            const [res] = await Promise.all([
                apiCall('post', '', {}, '', 'getMainDashboardData', false),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_MAIN_DASHBOARD_DATA,
                    payload: {
                        mainDashboardData: res.data.data.mainDashboardData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const setBidHistoryValue = async (data) => {
        dispatch({
            type: BID_HISTORY_VALUE,
            payload: data,
        })
    }

    const clearBidHistoryValue = () =>
        dispatch({
            type: CLEAR_BID_HISTORY,
        })

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allDamageTypes: state.allDamageTypes,
                allPackageTypes: state.allPackageTypes,
                allBidIncrements: state.allBidIncrements,
                allNotifications: state.allNotifications,
                allPrograms: state.allPrograms,
                allItemStatus: state.allItemStatus,
                configVariables: state.configVariables,
                uploadedFiles: state.uploadedFiles,
                USStates: state.USStates,
                responseStatus: state.responseStatus,
                mainDashboardData: state.mainDashboardData,
                bidHistoryValue: state.bidHistoryValue,
                uploadImage,
                setUSState,
                setBidHistoryValue,
                clearBidHistoryValue,
                getGlobalVariable,
                resetDropZone,
                deleteImage,
                getMainDashboardData,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
