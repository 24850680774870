import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Layout from '../../custom/components/templates/Layout'
import CustomInput from '../../custom/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
})

function createData(email, subject, date) {
    return { email, subject, date }
}

const rows = [
    createData('test.nellisauction@gmail.com', 'Items in your area has started!', '09/09/2020'),
    createData('test.nellisauction@gmail.com', 'Items in your area has started!', '09/09/2020'),
    createData('test.nellisauction@gmail.com', 'Items in your area has started!', '09/09/2020'),
    createData('test.nellisauction@gmail.com', 'Items in your area has started!', '09/09/2020'),
]

const EmailLogs = () => {
    const classes = useStyles()

    return (
        <Layout>
            <div className="dashboard communications">
                <div className="commActBox d-flex justify-content-start align-items-center">
                    <CustomInput label="Subject" placehloder="Enter Subject" />
                    <CustomInput label="Email address" placehloder="Enter email address" />
                    <PrimaryButton label="Search" />
                </div>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell align="right">Subject</TableCell>
                                <TableCell align="right">Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row.email}
                                    </TableCell>
                                    <TableCell align="right">{row.subject}</TableCell>
                                    <TableCell align="right">{row.date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Layout>
    )
}
export default EmailLogs
