import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../custom/components/templates/Layout'
import CustomTable from '../../custom/components/molecules/CustomTable'
import TransactionContext from '../../custom/context/transaction/transactionContext'
import CustomSearch from '../../custom/components/molecules/CustomSearch'
import { useFormik } from 'formik'

const Transaction = (props) => {
    const transactionContext = useContext(TransactionContext)

    const {
        all_payment,
        getAllPayment,
        responseStatus: responseStatusInvoice,
        clearResponse: clearResponseInvoice,
    } = transactionContext

    let action = props.match.params.action

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            fielddate: 'p.createdAt',
            fromdate: '',
            todate: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            getAllPayment(values)
        },
    })

    useEffect(() => {
        if (all_payment) {
            setTableBody(all_payment.records.length ? all_payment.records : [])
        }
    }, [all_payment])

    useEffect(() => {
        setTableBody([])
        formik.values.action = action
        formik.values.page = 1
        formik.values.fromdate = ''
        formik.values.todate = ''
        formik.values.searchterm = ''
        getAllPayment(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    const tableValues = [
        {
            field: 'transactionId',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Transaction ID',
        },
        {
            field: 'amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Amount',
        },
        {
            field: 'paymentMethod,maskedCardNumber,cardType',
            type: 'payment_method',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Method',
        },
        ,
        {
            field: 'email',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Email',
        },
        {
            field: 'createdAt',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Created Date',
        },
        {
            field: 'transactionType',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
    ]

    const tableActions = []

    const searchInfo = [
        {
            label: 'Transaction ID',
            placeholder: 'Enter transaction ID',
            class: 'col-4',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
        {
            label: 'Created From Date',
            placeholder: 'Date Ending from',
            class: 'col-4',
            type: 'date',
            name: 'fromdate',
            formik: formik,
        },
        {
            label: 'Created To Date',
            placeholder: 'Date Ending To',
            class: 'col-4',
            type: 'date',
            name: 'todate',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="dashboard invoice">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_payment}
                    changeTableFunction={getAllPayment}
                />
            </div>
        </Layout>
    )
}

export default Transaction
