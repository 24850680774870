import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../FullScreenPopup'
import { useFormik } from 'formik'
import CustomTable from '../../molecules/CustomTable'
import ProductContext from '../../../context/product/productContext'
import CommonContext from '../../../context/common/commonContext'

const BidHistory = () => {
    const productContext = useContext(ProductContext)
    const { search_allbidhistory, getAllBidHistory } = productContext

    const commonContext = useContext(CommonContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext

    const [tableBody, setTableBody] = useState([])
    const [data, setData] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                project_id: {
                    value: 0,
                    type: 'in',
                    field: 'b.project_id',
                },
            },
        },
        onSubmit: (values) => {
            getCreditHistory(values)
        },
    })

    useEffect(() => {
        if (bidHistoryValue) {
            formikData.values.filters.project_id.value = bidHistoryValue
            setData({ popup: true, id: bidHistoryValue })
            clearBidHistoryValue()
        }
    }, [bidHistoryValue])

    useEffect(() => {
        if (formikData.values.filters.project_id.value !== 0) {
            getAllBidHistory(formikData.values)
        }
    }, [formikData.values.filters.project_id.value])

    useEffect(() => {
        if (search_allbidhistory) {
            setTableBody(search_allbidhistory.records.length ? search_allbidhistory.records : [])
        }
    }, [search_allbidhistory])

    const tableValues = [
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'user_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'User ID',
        },
        {
            field: 'users_first_name,users_last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
        {
            field: 'proposed_amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Amount',
        },
    ]

    const tableActions = []

    return (
        <FullScreenPopup
            modaltitle={`Bid History`}
            open={data.popup}
            handleClose={() => setData({ ...data, popup: false })}
        >
            <div className="wallet">
                <div className="fspBody">
                    <CustomTable
                        formik={formikData.values}
                        tableBody={tableBody}
                        tableValues={tableValues}
                        tableActions={tableActions}
                        allData={search_allbidhistory}
                        changeTableFunction={getAllBidHistory}
                    />
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default BidHistory
