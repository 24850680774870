import React, { useContext, useState, useEffect } from 'react'

import CustomInput from '../../custom/components/atoms/Inputs/CustomInput'
import CustomSelect from '../../custom/components/atoms/Inputs/CustomSelect'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import SecondaryButton from '../../custom/components/atoms/SecondaryButton'
import FullScreenPopup from '../../custom/components/organisms/FullScreenPopup'
import EmployeeContext from '../../custom/context/employee/empContext'
import CommonContext from '../../custom/context/common/commonContext'
import AuthContext from '../../custom/context/auth/authContext'
import { mapData, handleRedirectInternal } from '../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const EmployeeManage = (props) => {
    const employeeContext = useContext(EmployeeContext)
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)

    const { user } = authContext
    const { single_employee, getSingleEmployee, employeeAction } = employeeContext
    const { USStates } = commonContext
    const permission = [
        {
            id: '/dashboard',
            description: 'Dashboard',
        },
        {
            id: '/auction/all',
            description: 'Auction Lots',
        },
        {
            id: '/listing/open',
            description: 'Active Listing',
        },
        {
            id: '/listing/sold',
            description: 'Sold Listing',
        },
        {
            id: '/listing/unsold',
            description: 'Unsold Listing',
        },
        {
            id: '/listing/draft',
            description: 'Draft Listing',
        },
        {
            id: '/users/all',
            description: 'All Users',
        },
        {
            id: '/users/active',
            description: 'Active Users',
        },
        {
            id: '/users/deactivate',
            description: 'Deactivated Users',
        },
        {
            id: '/report',
            description: 'Report Management',
        },
        {
            id: '/communications/email',
            description: 'Email Template',
        },
        {
            id: '/communications/sms',
            description: 'SMS Template',
        },
        {
            id: '/communications/emailLogs',
            description: 'Email Logs',
        },
        {
            id: '/invoice/unpaid',
            description: 'Invoice Unpaid',
        },
        {
            id: '/invoice/paid',
            description: 'Invoice Paid',
        },
        {
            id: '/return/pending',
            description: 'Return Pending',
        },
        {
            id: '/return/completed',
            description: 'Return Completed',
        },
        {
            id: '/transaction/payment',
            description: 'Transactions Payment',
        },
        {
            id: '/transaction/refund',
            description: 'Transactions Refund',
        },
    ]
    const [permissionSelect, setPermissionSelect] = useState(permission)
    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        emp_email: Yup.string().email('Invalid email format').required('Required!'),
        confirm_email:
            data.status === 'new'
                ? Yup.string()
                      .email('Invalid email format')
                      .oneOf([Yup.ref('emp_email')], "Email's not match")
                      .required('Required!')
                : Yup.string(),
        emp_firstname: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        emp_lastname: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        emp_address1: Yup.string().required('Required!'),
        emp_phone: Yup.string()
            .test('isValid', 'Invalid Phone Number', (value) => {
                return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)
            })
            .required('Required!'),
        emp_type: Yup.string().required('Required!'),
        emp_city: Yup.string().required('Required!'),
        emp_state: Yup.string().required('Required!'),
        emp_zip: Yup.string().required('Required!'),
        emp_permissions: Yup.array().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            emp_email: '',
            confirm_email: '',
            password: '',
            confirm_password: '',
            emp_firstname: '',
            emp_lastname: '',
            emp_address1: '',
            emp_phone: '',
            emp_type: 'employee',
            emp_city: '',
            emp_state: '',
            emp_zip: '',
            emp_permissions: [],
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            values.emp_phone = values.emp_phone.replace(/[^+0-9]/g, '')
            employeeAction(values)
        },
    })

    useEffect(() => {
        resetFrom()
    }, [props])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleEmployee(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        if (single_employee.record && data.status === 'edit') {
            const user = single_employee.record
            formik.values.emp_email = user.emp_email
            formik.values.emp_address1 = user.emp_address1
            formik.values.emp_city = user.emp_city
            formik.values.emp_firstname = user.emp_firstname
            formik.values.emp_lastname = user.emp_lastname
            formik.values.emp_phone = user.emp_phone
            formik.values.emp_type = user.emp_type
            formik.values.emp_state = user.emp_state
            formik.values.emp_zip = user.emp_zip
            formik.values.emp_permissions = user.emp_permissions.split(',')
            console.log('user.emp_phone', user.emp_phone)
        } else {
            resetFrom()
        }
    }, [single_employee])

    const resetFrom = () => {
        formik.values.emp_email = ''
        formik.values.emp_address1 = ''
        formik.values.emp_city = ''
        formik.values.emp_firstname = ''
        formik.values.emp_lastname = ''
        formik.values.emp_phone = ''
        formik.values.emp_type = 'employee'
        formik.values.emp_state = ''
        formik.values.emp_zip = ''
        formik.values.emp_permissions = []
        formik.handleReset()
    }

    const personalDetailsNew = [
        {
            label: 'First name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-6',
            name: 'emp_firstname',
            formik: formik,
        },
        {
            label: 'Last Name',
            placeholder: 'Enter last name',
            type: 'text',
            class: 'col-6',
            name: 'emp_lastname',
            formik: formik,
        },
        {
            label: 'Phone Number',
            placeholder: 'Enter phone number',
            type: 'text',
            class: 'col-6',
            name: 'emp_phone',
            formik: formik,
        },
        {
            label: 'Employee Type',
            placeholder: 'Enter employee type',
            class: 'col-6',
            type: 'select',
            options:
                user.emp_type === 'super_admin'
                    ? [
                          { value: 'super_admin', show: 'Super admin' },
                          { value: 'admin', show: 'Admin' },
                          { value: 'employee', show: 'Employee' },
                      ]
                    : user.emp_type === 'admin'
                    ? [{ value: 'employee', show: 'Employee' }]
                    : [{ value: 'employee', show: 'Employee' }],
            name: 'emp_type',
            formik: formik,
        },
    ]
    if (data.status === 'new') {
        personalDetailsNew.push(
            {
                label: 'Email Address',
                placeholder: 'Enter email address',
                type: 'text',
                class: 'col-6',
                name: 'emp_email',
                formik: formik,
            },
            {
                label: 'Confirm Email Address',
                placeholder: 'Confirm your email address',
                type: 'text',
                class: 'col-6',
                name: 'confirm_email',
                formik: formik,
            },
        )
    } else {
        personalDetailsNew.push({
            label: 'Email Address',
            placeholder: 'Enter email address',
            type: 'text',
            class: 'col-6',
            name: 'emp_email',
            disabled: true,
            formik: formik,
        })
    }
    const locationDetails = [
        {
            label: 'Street Address',
            placeholder: 'Enter street address',
            type: 'text',
            class: 'col-6',
            name: 'emp_address1',
            formik: formik,
        },
        {
            label: 'State',
            placeholder: 'Enter your state',
            class: 'col-6',
            type: 'select',
            options: USStates,
            name: 'emp_state',
            formik: formik,
        },
        {
            label: 'City',
            placeholder: 'Enter city',
            type: 'text',
            class: 'col-6',
            name: 'emp_city',
            formik: formik,
        },
        {
            label: 'ZIP',
            placeholder: 'Enter ZIP',
            type: 'number',
            class: 'col-6',
            name: 'emp_zip',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (formik.values.emp_type === 'super_admin') {
            permission.push(
                {
                    id: '/employees/all',
                    description: 'All Employees',
                },
                {
                    id: '/settings/static',
                    description: 'Static Pages',
                },
                {
                    id: '/settings/general',
                    description: 'General Settings',
                },
            )
            setPermissionSelect(permission)
        } else if (formik.values.emp_type === 'admin') {
            permission.push({
                id: '/employees/all',
                description: 'All Employees',
            })
            setPermissionSelect(permission)
        } else {
            setPermissionSelect(permission)
        }
    }, [formik.values.emp_type])

    const permissionDetails = [
        {
            type: 'check',
            class: 'd-flex align-items-center flex-wrap',
            name: 'emp_permissions',
            formik: formik,
            options: permissionSelect,
        },
    ]

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW EMPLOYEE'
                    : `EDIT EMPLOYEE: ${single_employee.record.emp_firstname} ${single_employee.record.emp_lastname}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <h2 className="dashTitle">PERSONAL DETAILS</h2>
                        <div className="row">{Object.values(mapData(personalDetailsNew))}</div>

                        <h2 className="dashTitle">LOCATION DETAILS</h2>
                        <div className="row">{Object.values(mapData(locationDetails))}</div>

                        <h2 className="dashTitle">PERMISSIONS</h2>
                        {Object.values(mapData(permissionDetails))}
                        <div className="actionButton d-flex justify-content-center align-items-center">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default EmployeeManage
