import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../custom/components/templates/Layout'
import CustomTable from '../../custom/components/molecules/CustomTable'
import CustomSearch from '../../custom/components/molecules/CustomSearch'
import { Button } from '@material-ui/core'
import { handleRedirectInternal } from '../../custom/common/components'
import { useFormik } from 'formik'
import AlertContext from '../../custom/context/alert/alertContext'
import CommunicationContext from '../../custom/context/communication/communicationContext'

import TemplateManage from './TemplateManage'

const AllTemplate = (props) => {
    const alertContext = useContext(AlertContext)
    const communicationContext = useContext(CommunicationContext)
    const history = useHistory()
    const { setAlert } = alertContext
    const {
        all_templates,
        getAllSearchTemplates,
        responseStatus: responseStatusCommuncation,
        clearResponse: clearResponseCommuncation,
    } = communicationContext

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        action: '',
    })

    let action = props.match.params.action

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id, action: action })
        } else {
            setManage({ popup, status: 'new', id: 0, action: action })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            getAllSearchTemplates(values)
        },
    })

    useEffect(() => {
        if (all_templates) {
            setTableBody(all_templates.records.length ? all_templates.records : [])
        }
    }, [all_templates])

    useEffect(() => {
        setTableBody([])
        formik.values.action = action
        formik.values.page = 1
        formik.values.searchterm = ''
        getAllSearchTemplates(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    useEffect(() => {
        if (responseStatusCommuncation) {
            if (
                responseStatusCommuncation.from === 'Communicationtemplates' &&
                responseStatusCommuncation.status === 'success'
            ) {
                toggleFullScreenPopup(false)
                setTableBody([])
                formik.values.action = action
                formik.values.page = 1
                formik.values.searchterm = ''
                getAllSearchTemplates(formik.values)
            }
        }
    }, [responseStatusCommuncation])

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    let tableValues = []
    if (action === 'emailLogs') {
        tableValues = [
            {
                field: 'email',
                type: '',
                firstChild: true,
                sort: true,
                disablePadding: false,
                label: 'Email',
            },
            {
                field: 'subject',
                type: '',
                sort: false,
                firstChild: false,
                disablePadding: false,
                label: 'Subject',
            },
            {
                field: 'date_sent',
                type: 'datetime',
                sort: false,
                firstChild: false,
                disablePadding: false,
                label: 'Date Sent',
            },
        ]
    } else {
        tableValues = [
            {
                field: 'title',
                type: '',
                firstChild: true,
                sort: true,
                disablePadding: false,
                label: 'Subject',
            },
            {
                field: 'action',
                type: 'button',
                clickType: 'edit',
                onclick: onClickEdit,
                sort: false,
                firstChild: false,
                disablePadding: false,
                label: 'Action',
            },
        ]
    }

    const searchInfo = [
        {
            label: 'Subject',
            placeholder: 'Enter Subject',
            class: 'col-4',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="dashboard communications">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                {action !== 'emailLogs' ? (
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <Button
                            variant="outlined"
                            className="btnOutlined"
                            onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                        >
                            <span className="material-icons">person_add_alt_1</span>Add New Template
                        </Button>
                    </div>
                ) : null}

                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={[]}
                    allData={all_templates}
                    changeTableFunction={getAllSearchTemplates}
                />
            </div>
            <TemplateManage data={manage} function={toggleFullScreenPopup} />
        </Layout>
    )
}
export default AllTemplate
