import React, { useContext, useState, useEffect } from 'react'

import CustomInput from '../../custom/components/atoms/Inputs/CustomInput'
import CustomSelect from '../../custom/components/atoms/Inputs/CustomSelect'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import SecondaryButton from '../../custom/components/atoms/SecondaryButton'
import FullScreenPopup from '../../custom/components/organisms/FullScreenPopup'
import UserContext from '../../custom/context/user/userContext'
import CommonContext from '../../custom/context/common/commonContext'
import { Button } from '@material-ui/core'
import { mapData, handleRedirectInternal } from '../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const UserManage = (props) => {
    const userContext = useContext(UserContext)
    const commonContext = useContext(CommonContext)

    const { USStates } = commonContext
    const { single_user, getSingleUser, userAction, resendVerificationEmail } = userContext

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required!'),
        confirm_email:
            data.status === 'new'
                ? Yup.string()
                      .email('Invalid email format')
                      .oneOf([Yup.ref('email')], "Email's not match")
                      .required('Required!')
                : Yup.string(),
        first_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        last_name: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        address1: Yup.string().required('Required!'),
        phone: Yup.string()
            .test('isValid', 'Invalid Phone Number', (value) => {
                return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)
            })
            .required('Required!'),
        city: Yup.string().required('Required!'),
        state: Yup.string().required('Required!'),
        zip: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            email: '',
            confirm_email: '',
            password: '',
            confirm_password: '',
            first_name: '',
            last_name: '',
            address1: '',
            phone: '',
            city: '',
            state: '',
            zip: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            values.phone = values.phone.replace(/[^+0-9]/g, '')
            userAction(values)
        },
    })

    useEffect(() => {
        resetFrom()
    }, [props])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleUser(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        if (single_user.record && data.status === 'edit') {
            const user = single_user.record
            formik.values.email = user.email
            formik.values.address1 = user.address1
            formik.values.city = user.city
            formik.values.first_name = user.first_name
            formik.values.last_name = user.last_name
            formik.values.phone = user.phone
            formik.values.state = user.state
            formik.values.zip = user.zip
            console.log('chcking', single_user)
        } else {
            resetFrom()
        }
    }, [single_user])

    const resetFrom = () => {
        formik.values.email = ''
        formik.values.address1 = ''
        formik.values.city = ''
        formik.values.first_name = ''
        formik.values.last_name = ''
        formik.values.phone = ''
        formik.values.state = ''
        formik.values.zip = ''
        formik.handleReset()
    }

    const personalDetailsNew = [
        {
            label: 'First name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-6',
            name: 'first_name',
            formik: formik,
        },
        {
            label: 'Last Name',
            placeholder: 'Enter last name',
            type: 'text',
            class: 'col-6',
            name: 'last_name',
            formik: formik,
        },
        {
            label: 'Phone Number',
            placeholder: 'Enter phone number',
            type: 'text',
            class: 'col-6',
            name: 'phone',
            formik: formik,
        },
    ]
    if (data.status === 'new') {
        personalDetailsNew.push(
            {
                label: 'Email Address',
                placeholder: 'Enter email address',
                type: 'text',
                class: 'col-6',
                name: 'email',
                formik: formik,
            },
            {
                label: 'Confirm Email Address',
                placeholder: 'Confirm your email address',
                type: 'text',
                class: 'col-6',
                name: 'confirm_email',
                formik: formik,
            },
        )
    } else {
        personalDetailsNew.push({
            label: 'Email Address',
            placeholder: 'Enter email address',
            type: 'text',
            class: 'col-6',
            name: 'email',
            disabled: true,
            formik: formik,
        })
    }
    const locationDetails = [
        {
            label: 'Street Address',
            placeholder: 'Enter street address',
            type: 'text',
            class: 'col-6',
            name: 'address1',
            formik: formik,
        },
        {
            label: 'State',
            placeholder: 'Enter your state',
            class: 'col-6',
            type: 'select',
            options: USStates,
            name: 'confirm_email',
            formik: formik,
        },
        {
            label: 'City',
            placeholder: 'Enter city',
            type: 'text',
            class: 'col-6',
            name: 'city',
            formik: formik,
        },
        {
            label: 'ZIP',
            placeholder: 'Enter ZIP',
            type: 'number',
            class: 'col-6',
            name: 'zip',
            formik: formik,
        },
    ]

    const resendVerifyEmailHandler = () => {
        let formData = {
            userId: data.id,
            firstName: formik.values.first_name,
            lastName: formik.values.last_name,
            email: formik.values.email,
        }
        resendVerificationEmail(formData)
    }

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW USER'
                    : `EDIT USER: ${single_user.record.first_name} ${single_user.record.last_name}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <h2 className="dashTitle">PERSONAL DETAILS</h2>
                        <div className="row">{Object.values(mapData(personalDetailsNew))}</div>
                        <h2 className="dashTitle">LOCATION DETAILS</h2>
                        <div className="row">{Object.values(mapData(locationDetails))}</div>
                        {data.status !== 'new' ? (
                            <Button onClick={resendVerifyEmailHandler}>
                                <span className="material-icons" style={{ marginRight: '5px' }}>
                                    send
                                </span>
                                Resend Verification Email
                            </Button>
                        ) : null}

                        <div className="actionButton d-flex justify-content-center align-items-center">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default UserManage
