import React, { useContext, useEffect } from 'react'
import './Login.css'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../custom/components/atoms/Inputs/CustomInput'
import CheckBox from '../../custom/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import { LOGO, SITE_NAME } from '../../utils/UI'
import { mapData, handleRedirectInternal } from '../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../custom/context/auth/authContext'
import AlertContext from '../../custom/context/alert/alertContext'

const Login = () => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const { login, responseStatus, clearResponse, isAuthenticated } = authContext

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, '')
        }
    }, [isAuthenticated])

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required!'),
        password: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            login(values)
        },
    })

    const loginInfo = [
        {
            label: 'Email address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'Password',
            name: 'password',
            type: 'password',
            placeholder: 'Enter your password',
            class: 'col-12',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                setAlert(responseStatus.message, responseStatus.status)
                clearResponse()
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'dashboard')
                }
            }
        }
    }, [responseStatus])
    return (
        <div className="login">
            <div className="loginCnt">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="loginLogo">
                            <object type="image/svg+xml" data={LOGO} />
                        </div>
                        <div className="col-12 loginTitle">
                            <h2>Welcome to {SITE_NAME}</h2>
                        </div>
                        <div className="w-100">{Object.values(mapData(loginInfo))}</div>
                        <div className="fpCnt col-12 d-flex justify-content-between align-items-center mb-2">
                            <CheckBox label="Remember me" />
                            <Link to="#">Forgot Password ?</Link>
                        </div>
                        <div className="col-12">
                            <PrimaryButton label="Login" type="submit" btnSize="large" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login
