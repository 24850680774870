import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../custom/components/templates/Layout'
import CustomTable from '../../custom/components/molecules/CustomTable'
import TransactionContext from '../../custom/context/transaction/transactionContext'
import CustomSearch from '../../custom/components/molecules/CustomSearch'
import { useFormik } from 'formik'

const Receipts = (props) => {
    const transactionContext = useContext(TransactionContext)

    const {
        getAllReceipts,
        receipts,
        responseStatus: responseStatusInvoice,
        clearResponse: clearResponseInvoice,
    } = transactionContext

    let action = props.match.params.action

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            fielddate: 'r.createdAt',
            fromdate: '',
            todate: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            getAllReceipts(values)
        },
    })

    useEffect(() => {
        if (receipts) {
            setTableBody(receipts.records.length ? receipts.records : [])
        }
    }, [receipts])

    useEffect(() => {
        setTableBody([])
        formik.values.action = action
        formik.values.page = 1
        formik.values.fromdate = ''
        formik.values.todate = ''
        formik.values.searchterm = ''
        getAllReceipts(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    const tableValues = [
        {
            field: 'id',
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'firstName,lastName',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
        {
            field: 'locationName',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Store Location',
        },
        {
            field: 'createdAt',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Created Date',
        },
        {
            field: 'total',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Total Amount',
        },
        {
            field: 'status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
    ]

    const tableActions = []

    const searchInfo = [
        {
            label: 'Receipt ID',
            placeholder: 'Enter Receipt ID',
            class: 'col-4',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
        {
            label: 'Created From Date',
            placeholder: 'Date Ending from',
            class: 'col-4',
            type: 'date',
            name: 'fromdate',
            formik: formik,
        },
        {
            label: 'Created To Date',
            placeholder: 'Date Ending To',
            class: 'col-4',
            type: 'date',
            name: 'todate',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="dashboard invoice">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={receipts}
                    changeTableFunction={getAllReceipts}
                />
            </div>
        </Layout>
    )
}

export default Receipts
