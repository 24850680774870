import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../custom/components/templates/Layout'
import '../Dashboard/Dashboard.css'
import CustomTable from '../../custom/components/molecules/CustomTable'
import CustomSearch from '../../custom/components/molecules/CustomSearch'
import TextField from '@material-ui/core/TextField'
import PrimaryButton from '../../custom/components/atoms/PrimaryButton'
import SecondaryButton from '../../custom/components/atoms/SecondaryButton'

import { useFormik } from 'formik'
import AlertContext from '../../custom/context/alert/alertContext'
import ProductContext from '../../custom/context/product/productContext'
import AuctionContext from '../../custom/context/auction/auctionContext'
import CommonContext from '../../custom/context/common/commonContext'

import { mapData, handleRedirectInternal } from '../../custom/common/components'

import CustomDialog from '../../custom/components/organisms/Dialog'
import { Button } from '@material-ui/core'

const Listing = (props) => {
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)
    const auctionContext = useContext(AuctionContext)
    const { allLocations, allPrograms, allItemStatus } = useContext(CommonContext)

    const history = useHistory()
    const { setAlert } = alertContext
    const {
        all_products,
        activelots,
        getAllProducts,
        changeProductStatus,
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = productContext
    const {
        assignAuctionLot,
        responseStatus: responseStatusAuction,
        clearResponse: clearResponseAuction,
    } = auctionContext
    const [assignAuction, setAssignAuction] = React.useState(false)
    const [changeStatus, setChangeStatus] = React.useState(false)
    const changeAssignAuction = () => {
        setAssignAuction(!assignAuction)
    }
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    let action = props.match.params.action

    const [tableBody, setTableBody] = useState([])
    const [activeAuctions, setActiveAuctions] = useState([])

    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            fielddate: 'date_closed',
            fromdate: '',
            todate: '',
            page: 1,
            limit: 100,
            order: '',
            orderby: '',
            storageName: true,
            locationId: [],
            programId: [],
            itemStatusId: [],
            starRatingRange: [],
            retailPriceRange: [],
        },
        onSubmit: (values) => {
            getAllProducts(values)
        },
    })

    const [locationOptions, setLocationOptions] = useState([])
    const [programOptions, setProgramOptions] = useState([])
    const [itemStatusOptions, setItemStatusOptions] = useState([])
    const [starRatingRange, setStarRatingRange] = useState([0, 5])
    const [retailPriceRange, setRetailPriceRange] = useState([0, 1000])

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            project_id: '',
            auction_id: '',
        },
        onSubmit: (values) => {
            if (
                values.action === 'open' ||
                values.action === 'removed' ||
                values.action === 'relist' ||
                values.action === 'unsold'
            ) {
                changeProductStatus(values)
            } else {
                assignAuctionLot(values)
            }
        },
    })

    useEffect(() => {
        if (all_products) {
            setTableBody(all_products.records.length ? all_products.records : [])
        }
    }, [all_products])

    useEffect(() => {
        const lotsToShow = []
        if (activelots && activelots.length > 0) {
            activelots.map((lot) => {
                lotsToShow.push({ value: lot.id, show: lot.title })
            })
            setActiveAuctions(lotsToShow)
        } else {
            setActiveAuctions([])
        }
    }, [activelots])

    const clearFilter = () => {
        formik.values.page = 1
        formik.values.fromdate = ''
        formik.values.todate = ''
        formik.values.searchterm = ''
        formik.values.storageName = true
        formik.values.locationId = []
        formik.values.programId = []
        formik.values.itemStatusId = []
        formik.values.starRatingRange = []
        formik.values.starRatingRange = []
        formik.values.retailPriceRange = []
        setStarRatingRange([0, 5])
        setRetailPriceRange([0, 1000])
    }
    const clearSearch = () => {
        clearFilter()
        getAllProducts(formik.values)
    }

    useEffect(() => {
        formik.values.action = action
        setTableBody([])
        clearFilter()
        getAllProducts(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    useEffect(() => {
        console.log('formikSelection.values', formikSelection.values)
    }, [formikSelection.values])

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.project_id = data
        if (action === 'auction_assign') {
            if (activeAuctions.length) {
                formikSelection.values.auction_id = activeAuctions[0].value
            }
            setAssignAuction(true)
        } else if (
            action === 'open' ||
            action === 'removed' ||
            action === 'unsold' ||
            action === 'relist'
        ) {
            setChangeStatus(true)
        }
    }

    const assignAuctionData = [
        {
            label: 'Select existing Lots',
            type: 'select',
            placeholder: 'Select the auction lots',
            class: 'col-12',
            options: activeAuctions,
            name: 'auction_id',
            formik: formikSelection,
        },
    ]

    const tableValues = [
        {
            field: 'avatar',
            type: 'image',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Picture',
        },
        {
            field: 'title',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Title',
        },
        {
            field: 'date_added',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Start date',
        },
        {
            field: 'date_closed',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'End date',
        },
        {
            field: 'bidscount',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Bids',
        },
        {
            field: 'programName',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Program',
        },
        {
            field: 'retailPrice',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Retail Price',
        },
        {
            field: 'starRating',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Star Rating',
        },
        {
            field: 'locationName',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Location',
        },
        {
            field: 'storageName',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Storage Location',
        },
        {
            field: 'createdAt',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Created At',
        },
        {
            field: 'itemStatusName',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
    ]

    const tableActions = []

    if (action === 'draft') {
        tableActions.push(
            {
                label: 'Move to Active',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'open',
            },
            {
                label: 'Assign to Auction',
                icon: <span className="material-icons">add_task</span>,
                onclick: onSelectMultiProducts,
                type: 'auction_assign',
            },
            {
                label: 'Delete',
                icon: <span className="material-icons">delete</span>,
                onclick: onSelectMultiProducts,
                type: 'removed',
            },
        )
    } else if (action === 'open') {
        tableActions.push(
            {
                label: 'Move to Unsold',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'unsold',
            },
            {
                label: 'Delete',
                icon: <span className="material-icons">delete</span>,
                onclick: onSelectMultiProducts,
                type: 'removed',
            },
        )
    } else if (action === 'sold') {
        tableActions.push({
            label: 'Move to Unsold',
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'unsold',
        })
    } else if (action === 'unsold') {
        tableActions.push(
            {
                label: 'Delete',
                icon: <span className="material-icons">delete</span>,
                onclick: onSelectMultiProducts,
                type: 'removed',
            },
            {
                label: 'Relist',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'relist',
            },
        )
    }

    const searchInfo = [
        {
            label: 'Listing Title',
            placeholder: 'Enter listing title',
            class: 'col-4',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
        {
            label: 'Closed From Date',
            placeholder: 'Date Ending from',
            class: 'col-4',
            type: 'date',
            name: 'fromdate',
            formik: formik,
        },
        {
            label: 'Closed To Date',
            placeholder: 'Date Ending To',
            class: 'col-4',
            type: 'date',
            name: 'todate',
            formik: formik,
        },
        {
            label: 'Program',
            placeholder: 'Program',
            class: 'col-4',
            type: 'multiselect',
            name: 'programId',
            options: programOptions,
            formik: formik,
        },
        {
            label: 'Location',
            placeholder: 'Location',
            class: 'col-4',
            type: 'multiselect',
            name: 'locationId',
            options: locationOptions,
            formik: formik,
        },
        {
            label: 'Status',
            placeholder: 'Status',
            class: 'col-4',
            type: 'multiselect',
            name: 'itemStatusId',
            options: itemStatusOptions,
            formik: formik,
        },
        {
            label: 'Retail Price',
            class: 'col-4',
            type: 'range',
            rangeType: 'price',
            name: 'retailPrice',
            min: 0,
            max: 1000,
            sliderHandleChange: (event, newValue) => {
                setRetailPriceRange(newValue)
            },
            sliderHandleCommit: (event, newValue) => {
                formik.setFieldValue('retailPriceRange', newValue)
            },
            item: retailPriceRange,
            formik: formik,
        },
        {
            label: 'Star Rating',
            class: 'col-4',
            type: 'range',
            name: 'starRating',
            min: 0,
            max: 5,
            sliderHandleChange: (event, newValue) => {
                setStarRatingRange(newValue)
            },
            sliderHandleCommit: (event, newValue) => {
                formik.setFieldValue('starRatingRange', newValue)
            },
            item: starRatingRange,
            formik: formik,
        },
        {
            label: 'Only show putaway items',
            placeholder: 'Only show putaway items',
            class: 'col-4',
            type: 'checkbox',
            name: 'storageName',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (allPrograms) {
            setProgramOptions(
                allPrograms.map((ele) => {
                    return { show: ele.name, value: ele.id }
                }),
            )
        }
    }, [allPrograms])

    useEffect(() => {
        if (allLocations) {
            setLocationOptions(
                allLocations.map((ele) => {
                    return { show: ele.name, value: ele.id }
                }),
            )
        }
    }, [allLocations])

    useEffect(() => {
        if (allItemStatus) {
            setItemStatusOptions(
                allItemStatus.map((ele) => {
                    return { show: ele.name, value: ele.id }
                }),
            )
        }
    }, [allItemStatus])

    useEffect(() => {
        getAllProducts(formik.values)
    }, [
        formik.values.locationId,
        formik.values.storageName,
        formik.values.programId,
        formik.values.itemStatusId,
        formik.values.starRatingRange,
        formik.values.retailPriceRange,
    ])

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionLot'
            ) {
                setAssignAuction(false)
                getAllProducts(formik.values)
            }
        }
    }, [responseStatusAuction])

    useEffect(() => {
        if (responseStatusProduct) {
            if (
                responseStatusProduct.status === 'success' &&
                responseStatusProduct.from === 'productchange'
            ) {
                setChangeStatus(false)
                getAllProducts(formik.values)
            }
        }
    }, [responseStatusProduct])

    return (
        <Layout>
            <div className="dashboard listing">
                <CustomSearch searchInfo={searchInfo} formik={formik} clearSearch={clearSearch} />

                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_products}
                    changeTableFunction={getAllProducts}
                    rowsPerPageOptions={[100, 500, 1000, 5000]}
                />
            </div>
            <CustomDialog
                title={'Assign to lots'}
                open={assignAuction}
                function={changeAssignAuction}
            >
                <h5>Select from an existing lot to assign</h5>
                <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                    <div className="row lotassignCnf">
                        {Object.values(mapData(assignAuctionData))}
                    </div>
                    <div className="actionWrapper">
                        <Button onClick={() => changeAssignAuction()}>Cancel</Button>
                        <PrimaryButton type="submit" label="Assign" />
                    </div>
                </form>
            </CustomDialog>

            <CustomDialog
                title={'Change product status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default Listing
