import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Layout from '../../custom/components/templates/Layout'
import CommonContext from '../../custom/context/common/commonContext'
import './Dashboard.css'

const Dashboard = () => {
    const hue = 0
    const history = useHistory()

    const { getMainDashboardData, mainDashboardData } = useContext(CommonContext)
    const [cardList, setCardList] = useState([])

    useEffect(() => {
        getMainDashboardData()
    }, [])

    useEffect(() => {
        console.log('main dashboard data: ', mainDashboardData)
        if (Object.keys(mainDashboardData).length > 0) {
            let newCardList = []
            for (let type in mainDashboardData) {
                if (type === 'activeCount') {
                    newCardList.push({
                        label: 'Active',
                        count: mainDashboardData[type],
                        path: 'listing/open',
                    })
                } else if (type === 'draftCount') {
                    newCardList.push({
                        label: 'Draft',
                        count: mainDashboardData[type],
                        path: 'listing/draft',
                    })
                } else if (type === 'soldCount') {
                    newCardList.push({
                        label: 'Sold',
                        count: mainDashboardData[type],
                        path: 'listing/sold',
                    })
                } else if (type === 'unsoldCount') {
                    newCardList.push({
                        label: 'Unsold',
                        count: mainDashboardData[type],
                        path: 'listing/unsold',
                    })
                }
            }
            setCardList(newCardList)
        }
    }, [mainDashboardData])
    const handleRedirect = (path) => {
        history.push(`/${path}`)
        window.scrollTo(0, 0)
    }

    return (
        <Layout>
            <div className="dashboard d-flex justify-content-start align-items-center flex-wrap">
                {cardList.length > 0
                    ? cardList.map((data, index) => (
                          <div
                              key={index}
                              className="infoCard"
                              style={{ filter: `hue-rotate(${hue + index * 40}deg)` }}
                          >
                              <Button onClick={() => handleRedirect(data.path)}>
                                  <h3>{data.count}</h3>
                                  <h6>{data.label}</h6>
                              </Button>
                          </div>
                      ))
                    : null}
            </div>
        </Layout>
    )
}

export default Dashboard
