import {
    GET_ALL_USERS,
    GET_SINGLE_USER,
    GET_DASHBOARD_USER,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
} from './userTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_USERS:
            return {
                ...state,
                all_users: action.payload,
            }
        case GET_SINGLE_USER:
            return {
                ...state,
                single_user: action.payload,
            }
        case GET_DASHBOARD_USER:
            return {
                ...state,
                search_alldashboardproducts: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
