import {
    COMMON_VALUES,
    US_STATE_VALUE,
    UPLOADED_IMAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_MAIN_DASHBOARD_DATA,
    BID_HISTORY_VALUE,
    CLEAR_BID_HISTORY,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case COMMON_VALUES:
            return {
                ...state,
                allCategory: action.payload.allCategory,
                allNotifications: action.payload.allNotifications,
                allLocations: action.payload.allLocations,
                allCondition: action.payload.allCondition,
                allDamageTypes: action.payload.allDamageTypes,
                allPackageTypes: action.payload.allPackageTypes,
                allBidIncrements: action.payload.allBidIncrements,
                bidIncrementDefault: action.payload.bidIncrementDefault,
                configVariables: action.payload.configVariables,
                allPrograms: action.payload.allPrograms,
                allItemStatus: action.payload.allItemStatus,
            }
        case US_STATE_VALUE:
            return {
                ...state,
                USStates: action.payload,
            }
        case UPLOADED_IMAGE:
            return {
                ...state,
                uploadedFiles: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_MAIN_DASHBOARD_DATA:
            return {
                ...state,
                mainDashboardData: action.payload.mainDashboardData,
            }
        case BID_HISTORY_VALUE:
            return {
                ...state,
                bidHistoryValue: action.payload,
            }
        case CLEAR_BID_HISTORY:
            return {
                ...state,
                bidHistoryValue: 0,
            }
        default:
            return state
    }
}
